import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";
import { Radio, RadioGroup } from "@blueprintjs/core";
import styles from "../../../styles/FilterSection.module.scss";

const DOWNLOAD_FORMAT_OPTIONS = [
  { id: "xlsx", label: "Excel", value: "xlsx" },
  { id: "pdf", label: "PDF", value: "pdf" },
];

class RadioFilterSection extends React.Component {
  static propTypes = {
    tagType: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
      .isRequired,
    selected: PropTypes.array,
    onFilterChange: PropTypes.func.isRequired,
    isSingleInput: PropTypes.bool,
  };

  static defaultProps = {
    selected: [],
    isSingleInput: true,
  };

  handleFormatChange = (event) => {
    const selectedFormat = DOWNLOAD_FORMAT_OPTIONS.find(
      (format) => format.value === event.target.value
    );

    if (selectedFormat) {
      const filter = {
        id: selectedFormat.id,
        type: this.props.tagType,
        displayName: selectedFormat.label,
        value: selectedFormat.value,
        isSingleSelect: true,
      };

      ReactGA.event({
        category: "filter",
        action: "format_select",
        label: selectedFormat.value,
      });

      this.props.onFilterChange(filter);
    }
  };

  render() {
    const { selected } = this.props;

    const selectedFormat =
      Array.isArray(selected) && selected.length > 0 ? selected[0].value : null;

    return (
      <div className={styles.radioFilterContainer}>
        <RadioGroup
          onChange={this.handleFormatChange}
          selectedValue={selectedFormat}
          inline={true}
          className={styles.formatRadioGroup}
        >
          {DOWNLOAD_FORMAT_OPTIONS.map((format) => (
            <Radio
              key={format.id}
              value={format.value}
              label={format.label}
              className={styles.formatRadio}
            />
          ))}
        </RadioGroup>
      </div>
    );
  }
}

export default RadioFilterSection;
