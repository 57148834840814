/*
 * @component DateTimeRangePicker
 * @description A component that allows users to select both date and time ranges using BlueprintJS's DateRangePicker with time selection capabilities.
 *
 * @version 1.0.0
 * @since Feb27-2025
 *
 * @features
 * 1. Date Range Selection: Allows selection of start and end dates
 * 2. Time Selection: Integrated time picker for both start and end times
 * 3. Auto-fill Support: Compatible with API-driven date/time autofill
 * 4. Format Support: Displays dates in "MMM DD, YYYY hh:mm A" format
 * 5. Analytics Integration: Tracks date/time selection events via ReactGA
 *
 * @usage
 * <DateTimeRangePicker
 *   tagType="date_time_range"
 *   selected={[{ from: Date, to: Date }]}
 *   onFilterChange={(tag, value) => handleChange(tag, value)}
 *   isSingleInput={true}
 * />
 *
 * @props
 * - tagType (string|string[]): Identifier for the filter type (Required)
 * - selected (array): Array containing selected date range objects [{ from: Date, to: Date }]
 * - isSingleInput (boolean): Whether to show as a single input field
 * - onFilterChange (function): Callback when date/time selection changes
 *
 * @state
 * - dateRange: Array containing [fromDate, toDate] as Date objects
 *
 * @methods
 * - onChange: Handles changes to individual from/to dates
 * - onDateRangeChange: Handles changes to the entire date range
 * - onTimeChange: Handles time selection changes (applies to both dates)
 *
 * @dependencies
 * - @blueprintjs/datetime: For DateRangePicker and TimePicker components
 * - react-ga: For analytics tracking
 * - moment: For date manipulation (recommended)
 *
 * @calledBy FilterSection.js
 * @notes
 * - Time selection is synchronized between start and end dates
 * - Uses BlueprintJS's TimePicker with AM/PM format
 * - Supports keyboard navigation with arrow buttons
 * - Minute precision for time selection
 */
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { TimePrecision } from "@blueprintjs/datetime";
import { FORM_FIELD_TYPE } from "../../../constants";
import styles from "../../../styles/FilterSection.module.scss";
import { formatDateTime } from "../../../utils";
import Form from "../../common/Form";

class DateTimeRangePicker extends Component {
  static propTypes = {
    tagType: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
      .isRequired,
    selected: PropTypes.array,
    isSingleInput: PropTypes.bool,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: [],
  };

  state = {
    dateRange: this.props.selected.length
      ? [this.props.selected[0]?.from || null, this.props.selected[0]?.to || null]
      : [null, null],
  };

  onChange = (value, key) => {
    const { tagType, onFilterChange } = this.props;
    const { dateRange } = this.state;

    const updatedRange = {
      from: key === "from" ? value : dateRange[0],
      to: key === "to" ? value : dateRange[1],
    };

    let displayName = "";
    if (updatedRange.from) displayName += formatDateTime(updatedRange.from, "MMM DD, YYYY hh:mm A");
    if (updatedRange.from && updatedRange.to) displayName += " - ";
    if (updatedRange.to) displayName += formatDateTime(updatedRange.to, "MMM DD, YYYY hh:mm A");

    const tag = { ...updatedRange, id: tagType, type: tagType, displayName };

    this.setState({ dateRange: [updatedRange.from, updatedRange.to] }, () => {
      onFilterChange(tag, updatedRange.from || updatedRange.to);
      if (updatedRange.from || updatedRange.to)
        ReactGA.event({ category: "filter", action: "datetime", label: tagType });
    });
  };

  onDateRangeChange = (dateRange) => {
    this.setState({ dateRange }, () => {
      if (dateRange[0]) this.onChange(dateRange[0], "from");
      if (dateRange[1]) this.onChange(dateRange[1], "to");
    });
  };

  onTimeChange = (time) => {
    if (!time) return;

    const { dateRange } = this.state;
    const updatedRange = [...dateRange];

    if (updatedRange[0]) {
      const from = new Date(updatedRange[0]);
      from.setHours(time.getHours());
      from.setMinutes(time.getMinutes());
      this.onChange(from, "from");
    }
    if (updatedRange[1]) {
      const to = new Date(updatedRange[1]);
      to.setHours(time.getHours());
      to.setMinutes(time.getMinutes());
      this.onChange(to, "to");
    }
  };

  render() {
    const { dateRange } = this.state;
    const { selected } = this.props;
    const [selectedDate = {}] = selected;

    const fromDateTime = selectedDate?.from ? new Date(selectedDate?.from) : null;
    const toDateTime = selectedDate?.to ? new Date(selectedDate?.to) : null;

    return (
      <Form
        config={[
          {
            id: "dateRange",
            type: FORM_FIELD_TYPE.DATE_RANGE_PICKER,
            size: { lg: 12, md: 12, sm: 12, xs: 12 },
            handleDateChange: this.onDateRangeChange,
            dateRange: [fromDateTime, toDateTime],
            closeOnSelection: false,
            timePrecision: TimePrecision.MINUTE,
            formatDate: (date) => (date ? formatDateTime(date, "DD MMM YYYY, hh:mm A") : ""),
            parseDate: (str) => new Date(str),
            timePickerProps: {
              useAmPm: true,
              className: styles.timePicker,
              showArrowButtons: false,
              precision: TimePrecision.MINUTE,
              onChange: this.onTimeChange,
              value: dateRange[0] || dateRange[1] || new Date(),
            },
          },
        ]}
      />
    );
  }
}

export default DateTimeRangePicker;
