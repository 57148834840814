import React, { useEffect } from "react";
import styles from "../../../../../src/styles/Reports/ReportCard.module.scss";
import { TAG_RENDER_TYPE, TAG_RENDER_TYPE_MAP } from "../../../../constants";
import { getTagTypeDisplayName } from "../../../../utils";
import DateRangeFilterSection from "../../../common/Filters/DateRangeFilterSection";
import RadioFilterSection from "../../../common/Filters/RadioFilterSection";
import DateTimeRangePicker from "../../../common/Filters/DateTimeRangePicker";
import ReportSearchFilterSection from "./ReportSearchFilterSection";

const ReportFilters = (props) => {
  const { selected, filterType } = props;
  useEffect(() => {
    let check = true;
    if (check) {
      props.autoFillFilterData([filterType, selected]);
    }
    return () => {
      check = false;
    };
  }, [props, selected, filterType]);

  const renderTagSelector = () => {
    const { tagType } = props;
    switch (TAG_RENDER_TYPE_MAP[tagType]) {
      case TAG_RENDER_TYPE.DOWNLOAD_FORMAT:
        return <RadioFilterSection {...props} />;
      case TAG_RENDER_TYPE.DATE_RANGE:
        return <DateRangeFilterSection {...props} />;
      case TAG_RENDER_TYPE.DATE_TIME_RANGE:
        return <DateTimeRangePicker {...props} />;
      default:
        return <ReportSearchFilterSection {...props} />;
    }
  };
  const heading = getTagTypeDisplayName(props.tagType);
  return (
    <div className={styles.mandatoryFilterContainer}>
      <div className={styles.heading}>{heading}</div>
      {renderTagSelector()}
    </div>
  );
};

export default ReportFilters;
