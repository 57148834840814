import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { BUTTON_TYPE, Icons } from "prefab";
import classNames from "classnames";
import PageLoadingWithTabs from "../../../common/PageLoadingWithTabs";
import { bindDispatch } from "../../../../utils";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";
import * as constants from "../../../../constants";
import PageHeader from "../../../common/PageHeader";
import Breadcrumb from "../../../common/BreadCrumb";
import LocalizedButton from "../../../common/LocalizedButton";
import ButtonStyles from "../../../../styles/Button.module.scss";
import pageStyles from "../../../../styles/App.module.scss";
import TabView from "../../../common/Tabs";
import CampaignTree from "../CampaignStructure/CampaignTree";
import ViewFiles from "../common/Files";
import CampaignDetails from "../common/pages/CampaignDetails";
import TargetGroupSummary from "../common/pages/TargetGroupDetails";
import MediaSummary from "../common/pages/MediaDetails";
import ApproveCampaign from "./actions/ApproveCampaign";
import RequestChangesCampaign from "./actions/RequestChangesCampaign";
import RejectCampaign from "./actions/RejectCampaign";

const { CampaignIcon } = Icons;
const _LINKS = [{ name: "PageHeader.campaignApprovals", path: "/campaign-approvals" }];

export class CampaignApprovalView extends Component {
  state = {
    // isCampaignStructureLoading: true,
    // campaignStructure: {},
    // isCampaignStructureError: false,
  };

  componentDidMount = async () => {
    const {
      match: { params },
      actions,
    } = this.props;

    actions.getCampaignApproval(params);
    actions.getCampaignApprovalTargetGroups({ campaignId: params.campaignId });
    actions.getCampaignApprovalMedia({ campaignId: params.campaignId });
    if (params.action) {
      actions.openCampaignAction(params.action);
    }
    await actions.getTimesOfDayList();
    // await this.fetchCampaignTreeData();
  };

  // fetchCampaignTreeData = () => {
  //   const {
  //     match: { params },
  //   } = this.props;

  //   getCampaignTreeApi(params.campaignId)
  //     .then((campaignRes) =>
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         campaignStructure: campaignRes,
  //       })
  //     )
  //     .catch((err) => {
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         isCampaignStructureError: true,
  //         campaignStructure: null,
  //       });
  //     });
  // };

  closeCampaignAction = () => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;
    actions.closeCampaignAction();
    history.replace(`/campaign-approvals/${params.campaignId}/${params.tabId || "details"}`);
  };

  approve = (notes) => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;

    actions.approveCampaign(params.campaignId, notes, () => {
      history.replace(`/campaigns/campaigns/${params.campaignId}`);
    });
  };

  requestChanges = (notes) => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;

    actions.requestChangesCampaign(params.campaignId, notes, () => {
      history.replace(`/campaigns/campaigns/${params.campaignId}`);
    });
  };

  reject = (reason, notes) => {
    const {
      match: { params },
      actions,
      history,
    } = this.props;

    actions.rejectCampaign(params.campaignId, reason, notes, () => {
      history.replace(`/campaigns/campaigns/${params.campaignId}`);
    });
  };

  renderTabWithCampaignStructure = (tabPanel) => {
    // const { isCampaignStructureLoading, campaignStructure, isCampaignStructureError } = this.state;
    // if (isCampaignStructureError) return tabPanel;
    const {
      match: {
        params: { campaignId },
      },
    } = this.props;
    return (
      <div className={classNames(pageStyles.pageContainer, styles.tabContentWrapper)}>
        <div className={styles.tabPanel}>{tabPanel}</div>
        <CampaignTree
          // isLoading={isCampaignStructureLoading}
          // campaignTreeData={campaignStructure}
          campaignIdForSummary={campaignId}
        />
      </div>
    );
  };

  viewCampaignFiles = () => {
    const {
      history,
      actions,
      match: { params },
    } = this.props;
    history.push(`/campaign-approvals/${params.campaignId}/${params.tabId}/files`);
    actions.viewCampaignFiles([]);
    actions.getCampaignFiles(params.campaignId);
  };

  closeFile = () => {
    const {
      history,
      actions,
      match: { params },
    } = this.props;
    history.push(`/campaign-approvals/${params.campaignId}/${params.tabId}`);
    actions.updateCampaignFiles({ isOpen: false, files: [] });
  };

  render = () => {
    const {
      history,
      actions,
      campaignApprovals: {
        campaignApproval,
        campaignApprovalTargetGroups,
        isTabLoading,
        isLoading,
        action,
        isMediaDetailsLoading,
        campaignApprovalMedia,
        isTargetGroupDetailsLoading,
      },
      campaigns: { isCampaignFileLoading, campaignFiles },
      match: { params },
      timesOfDay: { data: timesOfDay },
      userData,
    } = this.props;

    if (isLoading) {
      return <PageLoadingWithTabs showBreadcrumb={true} tabsCount={2} />;
    }

    return (
      <div className="col-12 clearfix">
        <div className="col-12 clearfix">
          <Breadcrumb
            isLoading={isLoading}
            history={history}
            links={[..._LINKS, { name: `${campaignApproval.name}` }]}
          />
        </div>
        <PageHeader
          name={`${campaignApproval.name}`}
          headerIcon={<CampaignIcon />}
          isLoading={isLoading}
          renderRightSideComponent={() => {
            const {
              campaignApprovals: { campaignApproval },
              history,
              actions,
              userData,
            } = this.props;

            return (
              <>
                {/* <LocalizedButton
                  scope={constants.SCOPES.CAMPAIGNS}
                  scopeAction={constants.SCOPE_ACTIONS.READ}
                  userData={userData}
                  buttonType={BUTTON_TYPE.PRIMARY}
                  className={ButtonStyles.buttonSpacing}
                  text="Button.viewFiles"
                  iconName="ViewIcon"
                  onClick={this.viewCampaignFiles}
                /> */}
                {campaignApproval.status === constants.CAMPAIGN_STATUSES.PROPOSED && (
                  <>
                    <LocalizedButton
                      scope={constants.SCOPES.CAMPAIGNS}
                      scopeAction={constants.SCOPE_ACTIONS.APPROVE}
                      userData={userData}
                      className={ButtonStyles.buttonSpacing}
                      buttonType={BUTTON_TYPE.PRIMARY}
                      text="Button.requestChanges"
                      iconName="EditIcon"
                      onClick={() => {
                        actions.openCampaignAction("request_change");
                        history.replace(
                          `/campaign-approvals/${params.campaignId}/${
                            params.tabId || "details"
                          }/request_change`
                        );
                      }}
                    />
                    <LocalizedButton
                      scope={constants.SCOPES.CAMPAIGNS}
                      scopeAction={constants.SCOPE_ACTIONS.APPROVE}
                      userData={userData}
                      className={ButtonStyles.buttonSpacing}
                      buttonType={BUTTON_TYPE.DESTRUCTIVE}
                      text="Button.reject"
                      iconName="CancelIcon"
                      onClick={() => {
                        actions.openCampaignAction("reject");
                        history.replace(
                          `/campaign-approvals/${params.campaignId}/${
                            params.tabId || "details"
                          }/reject`
                        );
                      }}
                    />
                    <LocalizedButton
                      scope={constants.SCOPES.CAMPAIGNS}
                      scopeAction={constants.SCOPE_ACTIONS.APPROVE}
                      userData={userData}
                      className={ButtonStyles.buttonSpacing}
                      buttonType={BUTTON_TYPE.CONSTRUCTIVE}
                      text="Button.approve"
                      iconName="ConfirmIcon"
                      onClick={() => {
                        actions.openCampaignAction("approve");
                        history.replace(
                          `/campaign-approvals/${params.campaignId}/${
                            this.props.match.params.tabId || "details"
                          }/approve`
                        );
                      }}
                    />
                  </>
                )}
              </>
            );
          }}
        />
        <div className={styles.tabsContainer}>
          <TabView
            id="campaignTabs"
            selectedTabId={params.tabId}
            defaultSelectedTabId="details"
            tabs={[
              {
                id: "details",
                title: "Campaign Details",
                goto: `/campaign-approvals/${campaignApproval.id}/details`,
                panel: this.renderTabWithCampaignStructure(
                  <CampaignDetails
                    params={params}
                    campaign={campaignApproval}
                    isLoading={isLoading}
                    userData={userData}
                    showEstimates
                  />
                ),
              },
              {
                id: "target-groups",
                title: "Target Groups",
                goto: `/campaign-approvals/${campaignApproval.id}/target-groups`,
                panel: this.renderTabWithCampaignStructure(
                  <TargetGroupSummary
                    params={params}
                    isLoading={isTabLoading}
                    countries={campaignApproval?.countries}
                    isDetailsLoading={isTargetGroupDetailsLoading}
                    targetGroups={campaignApprovalTargetGroups?.data}
                    timesOfDay={timesOfDay}
                    getTargetGroupDetails={actions.getCampaignApprovalTargetGroupDetails}
                    showEdit={false}
                    showDuplicate={false}
                    showDelete={false}
                  />
                ),
              },
              {
                id: "media",
                title: "Media",
                goto: `/campaign-approvals/${campaignApproval.id}/media`,
                panel: this.renderTabWithCampaignStructure(
                  <MediaSummary
                    params={params}
                    isLoading={isTabLoading}
                    isDetailsLoading={isMediaDetailsLoading}
                    mediaList={campaignApprovalMedia?.data}
                    timesOfDay={timesOfDay}
                    showEdit={false}
                    showDuplicate={false}
                    showDelete={false}
                    getMediaDetails={actions.getCampaignApprovalMediaDetails}
                  />
                ),
              },
            ]}
          />
        </div>
        <ApproveCampaign
          isOpen={action === "approve"}
          onClose={this.closeCampaignAction}
          onApprove={this.approve}
        />
        <RequestChangesCampaign
          isOpen={action === "request_change"}
          onClose={this.closeCampaignAction}
          onRequestChange={this.requestChanges}
        />
        <RejectCampaign
          isOpen={action === "reject"}
          onClose={this.closeCampaignAction}
          onReject={this.reject}
        />
        <ViewFiles
          campaignId={campaignApproval.id}
          isLoading={isCampaignFileLoading}
          isOpen={campaignFiles.isOpen}
          files={campaignFiles.files}
          onClose={this.closeFile}
          downloadFile={actions.downloadCampaignFile}
        />
      </div>
    );
  };
}
const mapStateToProps = createSelector(
  (state) => state.campaignApprovals,
  (state) => state.campaigns,
  (state) => state.userData,
  (state) => state.timesOfDay,
  (campaignApprovals, campaigns, userData, timesOfDay) => ({
    campaignApprovals,
    campaigns,
    userData: userData.user,
    timesOfDay,
  })
);

export default connect(mapStateToProps, bindDispatch)(CampaignApprovalView);
