import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  bindDispatch,
  getBusinessType,
  getCategoryNames,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
  createdByColumn,
  createdAtColumn,
  getColumnsByViewport,
  isSlateAdminCompanyUser,
} from "../../../../../utils";
import * as constants from "../../../../../constants";
import WithFetchList from "../../../../hoc/withFetchList";
import ActionIcon from "../../../../common/ActionIcon";
import {
  ActiveTableCell,
  ActiveTableCellColumnFromArray,
} from "../../../../common/ActiveTableCell";
import BrandTable from "../BrandTableView";
import { BRAND_LIST_COMMON_TAG_TYPES } from "../brandConstants";
import EditBrand from "../EditBrand";
import { getStatusDisplay } from "../../../campaigns/utils";

const DEFAULT_COLUMNS = [
  "Individual Brand",
  "Parent Brand",
  "Brand Type",
  "Categories",
  "Clients",
  "Status",
];
const MOBILE_COLUMNS = ["Individual Brand", "Parent Brand", "Categories", "Clients", "Status"];

class IndividualBrandsList extends Component {
  state = {
    selectedColumns: getColumnsByViewport(
      this.props.app.pageDimensions,
      MOBILE_COLUMNS,
      DEFAULT_COLUMNS
    ),
  };

  componentDidMount = () => {
    this.props.fetchData(null, true, [{ parentTagId: this.props.parentTagId }]);
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  editIndividualBrand = (prop) => {
    const { actions, editBrand } = this.props;
    actions.editBrand(prop.original);
    editBrand(prop.original.id);
  };
  editProduct = (prop) => {
    const { actions, editBrand } = this.props;
    actions.getProductById({ id: prop.original.id }, true);
    editBrand(prop.original.id);
  };
  render = () => {
    const {
      history,
      match: { params },
      brands: { individualBrandList, editBrand },
      businessTypes: { list },
      userData,
      addNewField,
      isViewOnly,
      hiddenColumn,
      title,
    } = this.props;
    const { selectedColumns, reorderedColumns } = this.state;

    const isAdmin = isSlateAdminCompanyUser(userData);

    const columns = checkScopes(
      [
        {
          id: "name",
          Header: "Individual Brand",
          parentAccessor: "name",
          childAccessor: "name",
        },
        {
          id: "parentBrandName",
          Header: "Parent Brand",
          accessor: (d) =>
            ActiveTableCell(d.parentBrandId, d.parentBrandName, () =>
              this.props.onFilterIdSelect(d.parentBrandId)
            ),
        },
        {
          id: "products",
          Header: "Product Count",
          sortable: false,
          accessor: (d) => (d.products ? d.products.length : ""),
        },
        {
          id: "brandType",
          Header: "Brand Type",
          parentAccessor: (d) =>
            ActiveTableCell(d.businessTypeTag, getBusinessType(list, d.businessType), () =>
              this.props.onFilterIdSelect(d.businessTypeTag)
            ),
        },
        {
          id: "categories",
          Header: "Categories",
          sortable: false,
          parentAccessor: (d) =>
            getCategoryNames(d.categories, (id) => this.props.onFilterIdSelect(id)),
          childAccessor: (d) => getCategoryNames(d.categories),
        },
        {
          id: "client",
          Header: "Clients",
          sortable: false,
          parentAccessor: (d) =>
            ActiveTableCellColumnFromArray(d.advertisers, this.props.onFilterIdSelect),
          childAccessor: (d) => ActiveTableCellColumnFromArray(d.advertisers),
        },
        {
          id: "createdByCompanyName",
          Header: "Company",
          accessor: (d) =>
            ActiveTableCell(d.createdByCompanyId, d.createdByCompanyName, () =>
              this.props.onFilterIdSelect(d.createdByCompanyId)
            ),
          onlyInAdmin: true,
        },
        {
          id: "status",
          Header: "Status",
          accessor: (d) =>
            getStatusDisplay(
              d.status,
              this.props.onFilterSelect,
              constants.TAG_TYPE.BRAND_STATUS,
              false
            ),
          onlyInAdmin: false,
        },
        lastUpdatedByColumn(this.props.onFilterIdSelect),
        lastUpdatedAtColumn(),
        createdByColumn(this.props.onFilterIdSelect),
        createdAtColumn(),
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          width: isViewOnly ? 64 : 96,
          parentCell: (props) => (
            <ActionIcon
              iconProps={checkScopes(
                [
                  {
                    toolTip: "Tooltip.view",
                    url: `/brands/individual-brands/${props.original.id}/details`,
                    iconName: "ViewIcon",
                  },
                  {
                    id: "edit",
                    scope: constants.SCOPES.BRANDS,
                    scopeAction: constants.SCOPE_ACTIONS.WRITE,
                    toolTip: "Tooltip.update",
                    onClick: () => this.editIndividualBrand(props),
                    iconName: "EditIcon",
                  },
                ].filter((icon) => (isViewOnly ? icon.id !== "edit" : icon)),
                userData
              )}
            />
          ),
          childCell: (props) => (
            <ActionIcon
              iconProps={checkScopes(
                [
                  {
                    toolTip: "Tooltip.view",
                    url: `/brands/products/${props.original.id}/details`,
                    iconName: "ViewIcon",
                  },
                  {
                    id: "edit",
                    scope: constants.SCOPES.BRANDS,
                    scopeAction: constants.SCOPE_ACTIONS.WRITE,
                    toolTip: "Tooltip.update",
                    onClick: () => this.editProduct(props),
                    iconName: "EditIcon",
                  },
                ].filter((icon) => (isViewOnly ? icon.id !== "edit" : icon)),
                userData
              )}
            />
          ),
        },
      ],
      userData
    ).filter((column) => (hiddenColumn ? column.id !== hiddenColumn : column));
    const addButtonProps = {
      addButton: true,
      buttonLabel: "Button.addIndividualBrand",
      addButtonScope: constants.SCOPES.BRANDS,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: addNewField,
      userData,
    };

    const tagTypes = [
      constants.TAG_TYPE.PARENT_BRAND,
      constants.TAG_TYPE.INDIVIDUAL_BRAND,
      ...(isAdmin ? [constants.TAG_TYPE.COMPANY] : []),
      constants.TAG_TYPE.BRAND_STATUS,
      ...BRAND_LIST_COMMON_TAG_TYPES,
    ];

    return (
      <>
        <BrandTable
          dataList={individualBrandList}
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          onColumnSelect={this.onColumnSelect}
          addButtonProps={addButtonProps}
          tagTypes={tagTypes}
          childIdentificationKey="products"
          onRowClick={({ original }) =>
            history.push(`/brands/individual-brands/${original.id}/details`)
          }
          onChildRowClick={({ original }) =>
            history.push(`/brands/products/${original.id}/details`)
          }
          {...this.props}
        />
        {!isViewOnly && (
          <EditBrand
            title={title ?? params?.tabId}
            brand={editBrand.brand}
            fetchDataList={() => {
              // Reset filter list and then trigger data refresh
              this.props.onFilterChange([]);
            }}
            {...this.props}
          />
        )}
      </>
    );
  };
}
export const IndividualBrands = WithFetchList("getIndividualBrandList", {
  sort: [{ id: "name" }],
})(IndividualBrandsList);

const mapStateToProps = createSelector(
  (state) => state.brands,
  (state) => state.businessTypes,
  (state) => state.productIdentificationNumberType,
  (state) => state.userData,
  (state) => state.app,
  (brands, businessTypes, productIdentificationNumberType, userData, app) => ({
    brands,
    businessTypes,
    productIdentificationNumberType,
    userData: userData.user,
    app,
  })
);
export default connect(mapStateToProps, bindDispatch)(withRouter(IndividualBrands));
