import classNames from "classnames";
import { compact, isEmpty, isEqual, uniqWith } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { fetchCampaignTargetTypes, fetchOrderRegions } from "../../../../../api";
import { BILLING_CYCLES, CAMPAIGN_STATUSES, CAMPAIGN_STEPS } from "../../../../../constants";
import styles from "../../../../../styles/CampaignCreate/CampaignForm.module.scss";
import { bindDispatch } from "../../../../../utils";
import Breadcrumb from "../../../../common/BreadCrumb";
import InfoBlockLoading from "../../../../common/InfoBlockLoading";
import PageLoadingWithTabs from "../../../../common/PageLoadingWithTabs";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import CampaignPageHeader from "../../common/CampaignPageHeader";
import { campaignStepChange, validateMandatoryCampaignFields } from "../../utils/campaignCreate";
import CampaignSteps from "../CampaignSteps";
import EmptyState from "../EmptyState";
import BasicDetails from "./BasicDetails";
import BillingDetails from "./BillingDetails";
import ContactDetails from "./ContactDetails";
import campaignRouteHandler from "..";

const orderIdType = ["Purchase Order ID", "DAVP Order ID"];

export class CampaignCreate extends Component {
  getInitialState = () => ({
    campaign: {
      name: "",
      advertiserId: "",
      countryIds: [],
      currencyId: "",
      campaignTargetTypeId: "",
      campaignTargetTypeName: "",
      billingInfo: {
        billingCycles: [],
        orderIdType: orderIdType[0],
        orderRegion: "",
      },
      // currency: {},
      maxPlaysPerScreen: 0,
      budgetedPlays: 0,
      effectiveRate: 0,
      campaignValue: null,
      validity: {},
      countries: [],
      contacts: [],
      status: CAMPAIGN_STATUSES.DRAFT,
    },
    orderIdType,
    campaignTypes: [],
    hasFormSaved: false,
    isFormEdited: false,
    isFetchingPreferences: false,
    isPageLoading: !this.props.isCampaignInvalid,
  });

  state = this.getInitialState();

  static propTypes = {
    isCampaignInvalid: PropTypes.bool,
  };

  static defaultProps = {
    isCampaignInvalid: false,
  };

  formContainer = React.createRef();

  //! Old componentDidMount method that was replaced by the new one below (kept for reference in case of issues)
  //  componentDidMount = async () => {
  //    const {
  //      isCampaignInvalid,
  //      match: {
  //        params: { campaignId, action },
  //      },
  //      actions,
  //      history,
  //      campaignCreate: { campaign },
  //    } = this.props;
  //    if (isCampaignInvalid) return false;

  //    if (campaignId && isEmpty(action) && action !== "details") {
  //      history.replace(`/campaign-create/${campaignId}/details`);
  //    }
  //    await actions.getBillingCycles();
  //    await actions.getCountries({ ps: -1 });
  //    const campaignTypes = await fetchCampaignTargetTypes();
  //    if (campaignId) {
  //      await actions.updateActiveStep({
  //        campaignStep: CAMPAIGN_STEPS.CAMPAIGN,
  //        campaignId: campaignId,
  //      });
  //    }

  //    this.setState({
  //      campaign: {
  //        ...this.state.campaign,
  //        ...campaign,
  //        ...campaign.billingInfo,
  //      },
  //      campaignTypes: campaignTypes.data,
  //      isPageLoading: false,
  //    });
  //  };

  componentDidMount = async () => {
    const {
      isCampaignInvalid,
      match: {
        params: { campaignId, action },
      },
      actions,
      history,
      campaignCreate: { campaign },
      userData,
      countries,
    } = this.props;

    if (isCampaignInvalid) return false;

    if (campaignId && isEmpty(action) && action !== "details") {
      history.replace(`/campaign-create/${campaignId}/details`);
    }
    await actions.getBillingCycles();
    await actions.getCountries({ ps: -1 });
    const campaignTypes = await fetchCampaignTargetTypes();
    const orderRegions = await fetchOrderRegions();
    if (campaignId) {
      await actions.updateActiveStep({
        campaignStep: CAMPAIGN_STEPS.CAMPAIGN,
        campaignId: campaignId,
      });
    }

    let defaultCountry;
    const countryList = countries?.list?.data;

    if (Array.isArray(countryList) && countryList.length > 0) {
      // Find the country that matches the user's company address country
      defaultCountry = countryList.find((c) => c.id === userData.company?.address?.country?.id);
    }

    const updatedCampaign = {
      ...this.state.campaign,
      ...campaign,
      ...campaign.billingInfo,
    };

    // If a default country was found and it's not already in the campaign's country list,
    // add it to both countryIds and countries arrays
    if (defaultCountry && !updatedCampaign.countryIds.includes(defaultCountry.id)) {
      updatedCampaign.countryIds = [defaultCountry.id];
      updatedCampaign.countries = [{ id: defaultCountry.id, name: defaultCountry.name }];
    }

    // Update the component's state with the new campaign data
    this.setState({
      campaign: updatedCampaign,
      campaignTypes: campaignTypes.data,
      orderRegions: orderRegions.data,
      isPageLoading: false,
    });
  };

  componentDidUpdate(prevProps) {
    const {
      campaignCreate: { campaign, activeStep },
      history,
    } = this.props;
    if (
      campaign?.id !== prevProps.campaignCreate.campaign?.id ||
      activeStep !== prevProps.campaignCreate.activeStep
    ) {
      this.setState({
        campaign: {
          ...this.state.campaign,
          ...campaign,
        },
      });
    }

    if (campaign?.id && prevProps.campaignCreate?.campaign?.id !== campaign.id) {
      if (validateMandatoryCampaignFields(campaign)) {
        history.push(`/campaign-create/${campaign.id}/target-groups`);
      } else {
        history.push(`/campaign-create/${campaign.id}/details`);
      }
      this.setState({
        campaign: {
          ...this.state.campaign,
          ...campaign,
        },
      });
    }
  }

  // Sets state in campaign object on change of input
  onInputChange = (data, key) => {
    //SLATE-1546 Feb7 Anushree:- Utilizing functional setState and spread operator for non-mutative state updates.
    this.setState((prevState) => {
      let campaign = { ...prevState.campaign };
      if (key === "advertiserId") {
        campaign[key] = data.id;
        campaign.advertiserName = data.name;
      } else if (key === "countryIds") {
        if (!isEmpty(data)) {
          //SLATE-1428 Dec8 Anushree:- country filed is saved as countryIds, countries
          //campaign[key] = uniqWith([...campaign[key], data], isEqual);
          campaign[key] = uniqWith([...campaign[key], data.id], isEqual);
          campaign.countries = uniqWith(
            [...campaign.countries, { id: data.id, name: data.name }],
            isEqual
          );
        } else {
          campaign[key] = [];
          campaign.countries = [];
        }
      } else if (key === "currencyId") {
        campaign[key] = data.id;
        campaign.currencyName = data.name;
      } else if (key === "billingCycleId") {
        campaign[key] = data.id;
        campaign.billingCycle = data;
      } else if (key === "validity") {
        const [fromDate, toDate] = data;
        const validity = {
          fromDate: fromDate
            ? moment(fromDate).format("YYYY-MM-DD")
            : campaign.validity?.fromDate ?? null,
          toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : campaign.validity?.toDate ?? null,
        };
        campaign[key] = validity;
      } else if (key === "campaignTargetTypeId") {
        campaign["campaignTargetTypeName"] = data.name;
        campaign[key] = data.id;
      } else if (key.split(" ")[0] === "billingInfo") {
        if (key.split(" ")[1] === "billingCycleId") {
          campaign = {
            ...campaign,
            billingInfo: {
              ...campaign.billingInfo,
              billingCycleId: data?.id,
              billingCycles: data,
            },
          };
        } else {
          const keyParts = key.split(" ");
          campaign = {
            ...campaign,
            [keyParts[0]]: { ...campaign[keyParts[0]], [keyParts[1]]: data },
          };
        }
      } else {
        campaign[key] = data;
      }
      return {
        campaign,
        isFormEdited: true,
      };
    });
  };

  handleCountryRemove = (index) => {
    const { campaign } = this.state;
    const filteredIds = campaign.countryIds.filter((ids, idIndex) => idIndex !== index);
    const filteredCountries = campaign.countries.filter((country, idIndex) => idIndex !== index);
    this.setState({
      campaign: {
        ...campaign,
        countryIds: filteredIds,
        countries: filteredCountries,
      },
      isFormEdited: true,
    });
  };

  getBillingCycles = () => {
    const { campaign } = this.state;
    const { billingCycles } = this.props;
    if (billingCycles.isLoading) return [];
    const billingCycleList = billingCycles.data.data;
    const validBillingCycles =
      //SLATE-1396 Nov21 Anushree:- api returns field with code ON_CAMPAIGN_COMPLETION
      //billingCycleList.filter((bc) => bc.code === BILLING_CYCLES.CAMPAIGN_COMPLETION) ?? [];
      billingCycleList.filter((bc) => bc.code === BILLING_CYCLES.ON_CAMPAIGN_COMPLETION) ?? [];
    if (billingCycleList.length > 0 && campaign.validity?.fromDate && campaign.validity?.toDate) {
      const campaignDuration = moment(campaign.validity.toDate).diff(
        campaign.validity.fromDate,
        "days"
      );
      if (campaignDuration > 7)
        validBillingCycles.push(billingCycleList.find((bc) => bc.code === BILLING_CYCLES.WEEKLY));
      if (campaignDuration > 14)
        validBillingCycles.push(
          billingCycleList.find((bc) => bc.code === BILLING_CYCLES.FORTNIGHTLY)
        );
      if (campaignDuration > 30)
        validBillingCycles.push(billingCycleList.find((bc) => bc.code === BILLING_CYCLES.MONTHLY));
    }

    return compact(validBillingCycles);
  };

  handleSaveCampaignAsDraft = () => {
    const { campaign } = this.state;
    if (campaign.name) {
      this.props.actions.createOrUpdateCampaign(campaign);
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  handleCampaignDelete = async () => {
    const {
      history,
      match: { params },
    } = this.props;
    const resp = await this.props.actions.deleteCampaign(params.campaignId);
    if (resp && resp.success) {
      history.push(`/campaigns`);
    }
  };

  handleChangeStep = async (preferredStep) => {
    const {
      match: { params },
      history,
      campaignCreate: { activeStep },
    } = this.props;
    const { campaign, isFormEdited } = this.state;
    if (preferredStep === activeStep + 1 && validateMandatoryCampaignFields(campaign)) {
      //SLATE-1397 Nov21 Anushree:- need to wait for campaign creation to access the id returned in its response
      // if (isFormEdited) this.props.actions.createOrUpdateCampaign(campaign);
      if (isFormEdited) await this.props.actions.createOrUpdateCampaign(campaign);
      const { id } = this.props.campaignCreate.campaign;
      this.props.actions.updateActiveStep({
        campaignStep: preferredStep,
        campaignId: id,
      });

      // this.props.history.push(`/campaign-create/${campaign.id}/target-groups`);
      this.props.history.push(`/campaign-create/${id}/target-groups`);
    } else {
      campaignStepChange(history, params, activeStep, preferredStep);
    }

    this.setState({
      hasFormSaved: true,
      isFormEdited: false,
    });
  };

  handleFormReset = () => {
    const {
      match: {
        params: { campaignId },
      },
      campaignCreate: { campaign },
    } = this.props;
    if (campaignId) {
      this.setState({
        campaign: campaign,
        advertiser: campaign.advertiser,
        validity: campaign.validity,
        country: campaign.countries,
        hasFormSaved: false,
        isFormEdited: false,
      });
    } else {
      this.setState({
        ...this.getInitialState(),
        isPageLoading: false,
      });
    }
  };

  renderCampaignTree = () => {
    const { campaign } = this.state;
    const {
      match: {
        params: { campaignId },
      },
    } = this.props;

    //Summary not shown for new campaign step 1
    if (this.props.isCampaignInvalid || !campaign.id) return null;
    return (
      <div className={styles.campaignTree}>
        <CampaignTree
          //isNewCampaign
          // isLoading={isPageLoading}
          // campaignTreeData={campaign}
          // campaignCreate={campaignCreate}
          campaignIdForSummary={campaignId}
        />
      </div>
    );
  };

  renderFormContent = () => {
    const {
      isCampaignInvalid,
      userData,
      countries: { list: countries },
      actions: { getCompanyAdvertiserPreferences, getCountries },
      campaignCreate: { campaignTargetGroups },
    } = this.props;
    const { campaign, hasFormSaved, isPageLoading, campaignTypes, orderIdType } = this.state;
    const childProps = {
      campaign,
      campaignTypes,
      orderIdType,
      orderRegions: this.state.orderRegions,
      onInputChange: this.onInputChange,
      disabled: !campaign.advertiserId,
      hasFormSaved: hasFormSaved,
    };

    if (isPageLoading) return <InfoBlockLoading />;
    if (isCampaignInvalid) return <EmptyState type="Campaign" />;

    //SLATE-1399 Nov21 Anushree:- companyId, action:getCompanyAdvertiserPreferences passed in BasicDetails
    return (
      <div className={classNames("col-12 clearfix", styles.formContainer)}>
        <BasicDetails
          {...childProps}
          companyId={userData.company?.id}
          companyPreferences={userData.company?.preferences}
          countryList={countries?.data ?? []}
          //searchCountries={searchCountries}
          searchCountries={getCountries}
          removeCountryItem={this.handleCountryRemove}
          getCompanyAdvertiserPreferences={getCompanyAdvertiserPreferences}
          campaignTargetGroups={
            campaign.id && campaignTargetGroups.data ? campaignTargetGroups.data : []
          }
        />
        <ContactDetails {...childProps} />
        <BillingDetails {...childProps} billingCycles={this.getBillingCycles()} />
      </div>
    );
  };

  render() {
    const {
      history,
      campaignCreate: { isCampaignLoading, activeStep, campaignTargetGroups },
    } = this.props;
    const { campaign, hasFormSaved, isFormEdited } = this.state;

    if (isCampaignLoading) return <PageLoadingWithTabs />;
    return (
      <div className="col-12 clearfix">
        <Breadcrumb
          history={history}
          links={[
            { name: "PageHeader.campaigns", path: "/campaigns" },
            {
              name: !campaign.id ? "PageHeader.newCampaign" : campaign.name,
              path: !campaign.id ? "/campaigns" : `/campaigns/campaigns/${campaign.id}/details`,
            },
            { name: "PageHeader.editCampaignDetails" },
          ]}
        />
        <div className={classNames("col-12 clearfix")}>
          <div className={styles.campaignCreateProgressBar}>
            <CampaignPageHeader
              title={!campaign.id ? "PageHeader.newCampaign" : campaign.name}
              onSaveCampaignAsDraft={this.handleSaveCampaignAsDraft}
              onDiscardCampaign={this.handleCampaignDelete}
              isDiscardDisabled={!campaign.id || campaign.status !== CAMPAIGN_STATUSES.DRAFT}
              hideDraftButton
            />
            <CampaignSteps
              history={history}
              activeStep={activeStep}
              validTillStep={campaignTargetGroups.data?.length > 0 ? activeStep + 1 : activeStep}
              hasFormSaved={hasFormSaved}
              disableReset={!isFormEdited}
              onChangeStep={this.handleChangeStep}
              onSaveDraft={this.handleSaveCampaignAsDraft}
              onReset={this.handleFormReset}
              isAdminUser={this.validateCreateCampaignAccess}
            />
          </div>
          <div className={classNames("col col-12", styles.pageContent)}>
            {this.renderFormContent()}
            {this.renderCampaignTree()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.campaignCreate,
  (state) => state.userData,
  (state) => state.billingCycles,
  (state) => state.countries,
  (campaignCreate, userData, billingCycles, countries) => ({
    campaignCreate,
    userData: userData.user,
    billingCycles,
    countries,
  })
);

export default connect(mapStateToProps, bindDispatch)(campaignRouteHandler(CampaignCreate));
