import classNames from "classnames";
import _, { isEmpty } from "lodash";
import { Icons } from "prefab";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  getCoolingTypes,
  getDigitalIntegrators,
  getExperiences,
  fetchPlaylistPackTypes as getPlaylistPackTypes,
  searchPlaylistTemplate as getPlaylistTemplate,
  getProjectionTypes,
  searchSegmentTypes as getSegmentTypes,
  getTheatreLocationTypes,
  getTheatreTypes,
  getUserScreensByIds,
  getUserTheatresByIds,
  uploadCSV,
} from "../../../../../api";
import { THEATRE_CRITERIA_MENU_LIST, THEATRE_CRITERIA_TYPES } from "../../../../../constants";
import styles from "../../../../../styles/CampaignCreate/TheatreBasedInput.module.scss";
import { bindDispatch, showToast } from "../../../../../utils";
import ApiSelect from "../../../../common/ApiSelect";
import InputDimensions from "../../../../common/DimensionsRangeInput";
import InputRange from "../../../../common/InputRange";
import LocalizedButton from "../../../../common/LocalizedButton";
import SelectByIdPanel from "../../../../common/SelectByIdPanel";
import Selector from "../../../../common/Selector";
import TextInput from "../../../../common/TextInput";
import UploadScreensCSV from "../../actions/UploadScreensCSV";
import { convertTextToCsvString } from "../../../../../utils/textToCsvFile";

const { AddFilledIcon, CancelIcon } = Icons;

class TheatreBasedInput extends Component {
  state = {
    selectedCriteriaValue: null,
    selectedIdList: [],
    matchedTheatreData: [],
    unmatchedTheatreData: [],
    selectedCriteriaType: THEATRE_CRITERIA_MENU_LIST[2],
    isPanelOpen: false,
    isPanelLoading: false,
    isUploadCsvPanelOpen: false,
    setPercent: 0,
    invalidIds: [],
    selectionData: [],
  };

  handleCriteriaSelectValue = ([data]) => {
    this.setState({
      selectedCriteriaValue: {
        id: data.id,
        name: data.value,
      },
    });
  };

  handlePanelClose = () =>
    this.setState({
      isPanelOpen: false,
      selectedIdList: [],
      selectionData: [],
      invalidIds: [],
      selectedCriteriaType: THEATRE_CRITERIA_MENU_LIST[2],
    });

  handleUploadPanelClose = () =>
    this.setState({
      isUploadCsvPanelOpen: false,
      selectedIdList: [],
      unmatchedTheatreData: [],
      matchedTheatreData: [],
      selectionData: [],
    });

  updateSelectedIds = (data) => {
    this.setState({ selectedIdList: data });
  };

  updateCriteriaInputValue = (value, accessor, parentAccessor = "") => {
    const criteriaInput = this.state.selectedCriteriaValue || {};

    if (parentAccessor) {
      criteriaInput[parentAccessor] = {
        ...criteriaInput[parentAccessor],
        [accessor]: value,
      };
    } else {
      criteriaInput[accessor] = value;
    }

    this.setState({
      selectedCriteriaValue: {
        ...criteriaInput,
        isRange: true,
      },
    });
  };

  handleCriteriaListUpdate = (criteriaListType) => {
    const { selectedCriteriaValue, selectedCriteriaType } = this.state;
    if (this.props.updateCriteriaList) {
      this.props.updateCriteriaList(
        {
          ...selectedCriteriaValue,
          type: selectedCriteriaType.id,
          typeDisplayName: selectedCriteriaType.label,
          //SLATE-1405 Nov29 Anushree:- tags is mandatory field for criteria
          //SLATE-1434 Dec11 Anushree:- empty array should be passed if selectedCriteriaValue.id is undefined
          tags: selectedCriteriaValue.id ? [selectedCriteriaValue.id] : [],
        },
        criteriaListType
      );

      this.setState({
        selectedCriteriaValue: null,
      });
    }
  };

  /**
   * Asynchronously retrieves selection data based on the selected criteria type and ID list.
   * Converts the selected ID list to a CSV file, uploads it, and processes the response.
   * Displays a toast message indicating whether valid data was found.
   * Updates the component state with the retrieved selection data and invalid IDs.
   *
   * @async
   * @function getSelectionData
   * @returns {Promise<Array>} A promise that resolves to an array of selection data.
   */
  getSelectionData = async () => {
    const { selectedIdList, selectedCriteriaType } = this.state;
    if (selectedIdList.length === 0) return [];
    // TODO: Update once actual api is completed
    const csvHeader =
      selectedCriteriaType.id === THEATRE_CRITERIA_TYPES.SCREEN_BY_ID ? "screen_id" : "theatre_id";
    const idString = selectedIdList.join("\n");
    const csvString = await convertTextToCsvString(idString, csvHeader);
    //conver csvString to Csv file and upload it
    const csvFile = new File([csvString], "theatre.csv", { type: "text/csv" });
    const [selectionData, invalidIds] = await this.uploadCSVFile(csvFile, false);

    if (selectionData.length === 0) {
      showToast(`No ${selectedCriteriaType.label} found`, false);
    } else {
      showToast(`Valid ${selectedCriteriaType.label} found`, true);
    }
    this.setState({
      isPanelLoading: false,
      selectionData: selectionData,
      invalidIds: invalidIds,
      selectedIdList: [],
    });
    return selectionData;
  };

  updateSelectedIdsByCriteriaType = async (type) => {
    this.setState({ isPanelLoading: true });

    const { selectionData: selectedData, selectedCriteriaType } = this.state;

    if (this.props.updateCriteriaList && selectedData.length > 0) {
      selectedData.forEach((item) => {
        const tagsInfos = [
          {
            context: {
              city: { name: item.cityName },
              province: { name: item.provinceName },
              country: { name: item.countryName },
            },
          },
        ];

        this.props.updateCriteriaList(
          {
            name: item.name,
            id: item.id,
            type: selectedCriteriaType.id,
            typeDisplayName: selectedCriteriaType.label,
            theatreName: item?.theatreName,
            tagsInfos,
            tags: [item.id],
          },
          type
        );
      });

      this.setState({
        selectedCriteriaValue: null,
        selectedIdList: [],
        isPanelOpen: false,
        selectionData: [],
        invalidIds: [],
        isPanelLoading: false,
        selectedCriteriaType: THEATRE_CRITERIA_MENU_LIST[2],
      });
    }
  };

  updateIdsFromCSVToCriteriaList = async (type) => {
    const { matchedTheatreData } = this.state;

    const selectionData = matchedTheatreData.flatMap(({ request, record }) => {
      const tagsInfos = [
        {
          context: {
            city: { name: record.address.city },
            province: { name: record.address.state },
            country: { name: record.address.country },
          },
        },
      ];

      const isUUID = (id) =>
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id);

      if (request.screen_id) {
        return record.auditoriums
          .filter(({ uuid, identifiers }) => {
            // Check if the screen_id is a UUID format for screens
            if (isUUID(request.screen_id)) {
              return uuid === request.screen_id;
            } else {
              // Check if ERPID is present in the identifiers and match with the screen_id
              return identifiers.includes(`qubecinema.com:${request.screen_id}`);
            }
          })
          .map(({ uuid, name }) => ({
            id: uuid,
            name,
            theatreName: record.name,
            tagsInfos,
            hasScreen: true,
          }));
      } else if (request.id) {
        return [
          {
            id: record.uuid,
            name: record.name,
            tagsInfos,
            hasScreen: false,
          },
        ];
      }

      return [];
    });

    if (this.props.updateCriteriaList && selectionData.length > 0) {
      selectionData.forEach(({ id, name, theatreName, tagsInfos, hasScreen }) => {
        const criteriaType = hasScreen
          ? THEATRE_CRITERIA_MENU_LIST[1]
          : THEATRE_CRITERIA_MENU_LIST[0];

        this.props.updateCriteriaList(
          {
            name,
            id,
            type: criteriaType.id,
            typeDisplayName: criteriaType.label,
            theatreName: hasScreen ? theatreName : undefined,
            tagsInfos,
            tags: [id],
          },
          type
        );
      });

      this.setState({
        selectedCriteriaValue: null,
        selectedIdList: [],
        isUploadCsvPanelOpen: false,
        unmatchedTheatreData: [],
        matchedTheatreData: [],
      });
    }
  };

  handleSelectByIdExclusion = async () => {
    this.setState({
      isPanelLoading: true,
    });
    const selectedData = await this.getSelectionData();
    this.updateCriteriaSelectionById("exclusions", selectedData);
  };

  validateCriteriaRangeValue = (data) => {
    if (isEmpty(data)) return false;
    const { rangeFrom, rangeTo } = data;
    if (rangeFrom && rangeTo) {
      return rangeFrom !== rangeTo && rangeFrom < rangeTo;
    }

    return rangeFrom || rangeTo;
  };

  validateSelectedCriteriaValue = () => {
    const { selectedCriteriaValue, selectedCriteriaType } = this.state;
    const { TICKET_PRICE, SEATING_CAPACITY } = THEATRE_CRITERIA_TYPES;

    if (selectedCriteriaType.id === THEATRE_CRITERIA_TYPES.AUDITORIUM_DIMENSIONS) {
      const { auditoriumWidth, auditoriumHeight, auditoriumLength } = selectedCriteriaValue;
      return (
        this.validateCriteriaRangeValue(auditoriumLength) &&
        this.validateCriteriaRangeValue(auditoriumWidth) &&
        this.validateCriteriaRangeValue(auditoriumHeight)
      );
    } else if ([TICKET_PRICE, SEATING_CAPACITY].includes(selectedCriteriaType.id)) {
      return this.validateCriteriaRangeValue(selectedCriteriaValue);
    }

    return !isEmpty(selectedCriteriaType);
  };

  renderSelectWithApi = (getDataList) => {
    const { selectedCriteriaType, selectedCriteriaValue } = this.state;
    return (
      <ApiSelect
        placeholder={`Select ${selectedCriteriaType.label}`}
        onSelect={this.handleCriteriaSelectValue}
        value={selectedCriteriaValue && selectedCriteriaValue.name}
        fetchAction={() => getDataList()}
        parseResults={(res) => {
          //SLATE-1427 Dec8 Anushree:- filter for the empty values added
          return res.data
            .map((item) => ({
              id: item.id,
              value: item.name,
            }))
            .filter((item) => {
              return item.value !== "" && item.value !== undefined && item.value !== null;
            });
        }}
        selected={(list) =>
          selectedCriteriaValue ? list.filter((item) => item.id === selectedCriteriaValue.id) : []
        }
        singleSelect
        showLabelInButton={false}
      />
    );
  };

  renderSearchByIdInput = () => {
    return (
      <>
        <TextInput
          className={styles.criteriaTextInput}
          onFocus={() => this.setState({ isPanelOpen: true })}
          placeholder={`Select ${this.state?.selectedCriteriaType?.label}`}
        />
      </>
    );
  };

  // This method is used to render the input field for adding by CSV
  renderAddByCSV = () => {
    return (
      <TextInput
        className={styles.criteriaTextInput}
        onFocus={() => this.setState({ isUploadCsvPanelOpen: true })}
        placeholder={`Select ${this.state?.selectedCriteriaType?.label}`}
      />
    );
  };

  renderInputByCriteriaType = () => {
    const { selectedCriteriaType, selectedCriteriaValue } = this.state;

    switch (selectedCriteriaType.id) {
      // case THEATRE_CRITERIA_TYPES.THEATRE_BY_ID:
      // case THEATRE_CRITERIA_TYPES.SCREEN_BY_ID:
      //   return this.renderSearchByIdInput();
      case THEATRE_CRITERIA_TYPES.THEATRE_TYPE:
        return this.renderSelectWithApi(getTheatreTypes);
      case THEATRE_CRITERIA_TYPES.THEATRE_LOCATION_TYPES:
        return this.renderSelectWithApi(getTheatreLocationTypes);
      case THEATRE_CRITERIA_TYPES.DIGITAL_INTEGRATORS:
        return this.renderSelectWithApi(getDigitalIntegrators);
      case THEATRE_CRITERIA_TYPES.PROJECTION_TYPES:
        return this.renderSelectWithApi(getProjectionTypes);
      case THEATRE_CRITERIA_TYPES.COOLING_TYPES:
        return this.renderSelectWithApi(getCoolingTypes);
      case THEATRE_CRITERIA_TYPES.EXPERIENCES:
        return this.renderSelectWithApi(getExperiences);
      case THEATRE_CRITERIA_TYPES.SEGMENT:
        return this.renderSelectWithApi(() => getSegmentTypes(this.props.companyId));
      case THEATRE_CRITERIA_TYPES.PLAYLIST_PACK:
        return this.renderSelectWithApi(getPlaylistPackTypes);
      case THEATRE_CRITERIA_TYPES.PLAYLIST_TEMPLATE:
        return this.renderSelectWithApi(getPlaylistTemplate);
      case THEATRE_CRITERIA_TYPES.SEATING_CAPACITY:
      case THEATRE_CRITERIA_TYPES.TICKET_PRICE:
        return (
          <InputRange
            onChange={(value, accessor) => this.updateCriteriaInputValue(value, accessor)}
            minimumPlaceholder={selectedCriteriaType.minPlaceholder}
            maximumPlaceholder={selectedCriteriaType.maxPlaceholder}
            minValue={selectedCriteriaValue ? selectedCriteriaValue.rangeFrom : ""}
            maxValue={selectedCriteriaValue ? selectedCriteriaValue.rangeTo : ""}
          />
        );
      case THEATRE_CRITERIA_TYPES.AUDITORIUM_DIMENSIONS:
        return (
          <InputDimensions
            onChange={(value, accessor, parentAccessor) =>
              this.updateCriteriaInputValue(value, accessor, parentAccessor)
            }
            dimensionSeparator={<CancelIcon width={16} height={16} />}
            dimensionWidth={selectedCriteriaValue ? selectedCriteriaValue.auditoriumWidth : {}}
            dimensionHeight={selectedCriteriaValue ? selectedCriteriaValue.auditoriumHeight : {}}
            dimensionLength={selectedCriteriaValue ? selectedCriteriaValue.auditoriumLength : {}}
          />
        );
      default:
        return null;
    }
  };

  /**
   * Uploads a CSV file and processes the data to separate matched and unmatched theatres and screens.
   *
   * @param {Event|File} e - The event object from the file input or the file itself.
   * @param {boolean} [isCsvUpload=true] - Flag to determine if the function is called for CSV upload or not.
   * @returns {Promise<void|Array>} - Returns void if isCsvUpload is true, otherwise returns an array of valid and invalid IDs.
   *
   * @throws Will throw an error if the CSV upload fails.
   */
  uploadCSVFile = async (e, isCsvUpload = true) => {
    const { token, campaignId } = this.props;
    const file = e.target?.files?.[0] || e;

    if (!file) {
      showToast("Please upload a CSV file", false);
      return;
    }

    const form = new FormData();
    form.append("file", file);

    try {
      const response = await uploadCSV(form, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      //Separating the matched and unmatched theatres and screens from QUBE-WIRE response
      const [theatresInDB, unmatchedIdList] = _.partition(
        _.sortBy(response.data, ["order"]),
        (t) => t.matched
      );

      //Separating the theatres and screens based on id matched my Theatre ID or Screen ID
      const [theaters, screens] = _.partition(
        theatresInDB,
        (t) => t.record.matched_on === "Theatre ID"
      );

      //Separating the valid and invalid theatres and screens
      let validTheatreList = [];
      let invalidTheatreList = [];
      let validScreenList = [];
      let invalidScreenList = [];
      let responseTheatreList = [];
      let responseScreenList = [];
      if (theaters.length > 0) {
        const theatreIds = new Set(theaters.map((t) => t.record.uuid));
        responseTheatreList = await getUserTheatresByIds(Array.from(theatreIds), campaignId).then(
          (res) => res.data.data
        );

        if (responseTheatreList && responseTheatreList.length > 0) {
          [validTheatreList, invalidTheatreList] = _.partition(theaters, (t) =>
            responseTheatreList.some(
              (r) => r.id === t.record.uuid && r.message !== "Invalid UUID/ERP ID"
            )
          );
        }
      }
      if (screens.length > 0) {
        const screenIds = new Set(
          screens.map((t) => t.record.auditoriums.filter((a) => a.selected)[0].uuid ?? null)
        );
        responseScreenList = await getUserScreensByIds(Array.from(screenIds), campaignId).then(
          (res) => res.data.data
        );
        if (responseScreenList && responseScreenList.length > 0) {
          [validScreenList, invalidScreenList] = _.partition(screens, (t) =>
            responseScreenList.some(
              (r) =>
                r.id === t.record.auditoriums.filter((a) => a.selected)[0].uuid &&
                r.message !== "Invalid UUID/ERP ID"
            )
          );
        }
      }

      //If is CSV upload is true then set the matched and unmatched theatre data
      //If is CSV upload is false then return the valid and invalid theatre and screen data
      if (isCsvUpload) {
        //setting the matched and unmatched theatre data
        this.setState({
          unmatchedTheatreData: [...unmatchedIdList, ...invalidTheatreList, ...invalidScreenList],
          matchedTheatreData: [...validTheatreList, ...validScreenList],
        });
      } else {
        if (theaters.length > 0) {
          if (responseTheatreList.length === 0)
            return [[], unmatchedIdList?.map((t) => t.request.id)];
          const [validIds, invalidIds] = _.partition(
            responseTheatreList,
            (t) => t.message !== "Invalid UUID/ERP ID"
          );
          return [
            validIds,
            [...invalidIds?.map((t) => t.id), ...unmatchedIdList?.map((t) => t.request.id)],
          ];
        } else if (screens.length > 0) {
          if (responseScreenList.length === 0)
            return [[], unmatchedIdList?.map((t) => t.request.screen_id)];
          const [validIds, invalidIds] = _.partition(
            responseScreenList,
            (t) => t.message !== "Invalid UUID/ERP ID"
          );
          return [
            validIds,
            [...invalidIds?.map((t) => t.id), ...unmatchedIdList?.map((t) => t.request.screen_id)],
          ];
        }
        return [[], unmatchedIdList?.map((t) => t.request.id)];
      }
    } catch (error) {
      showToast("Failed to upload CSV. Please try again.", false);
      this.setState({ setPercent: 0 });
    }
  };

  handleCriteriaChange = (selectedCriteriaType) => {
    this.setState({ selectedCriteriaType });
  };

  handleSelectByIdPanelClose = () => {
    this.setState({
      isPanelOpen: false,
      selectedIdList: [],
      selelctionData: [],
      invalidIds: [],
      selectedCriteriaType: THEATRE_CRITERIA_MENU_LIST[2],
    });
  };

  render() {
    const {
      isPanelOpen,
      isUploadCsvPanelOpen,
      selectedCriteriaValue,
      selectedCriteriaType,
      selectedIdList,
      isPanelLoading,
      unmatchedTheatreData,
      matchedTheatreData,
      invalidIds,
      selectionData,
    } = this.state;

    const { targetGroup } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.buttonWrapper}>
          <LocalizedButton
            id="selectByCsv"
            className={`${styles.button} ${styles.uploadCsvButton}`}
            iconName="UploadIcon"
            onClick={() => this.setState({ isUploadCsvPanelOpen: true })}
            text="Button.selectByCsv"
          />
          <LocalizedButton
            id="selectById"
            className={`${styles.button} ${styles.selectByIdButton}`}
            iconName="IDIcon"
            onClick={() => this.setState({ isPanelOpen: true })}
            text="Button.selectById"
          />
        </div>
        <div className={styles.contentWrapper}>
          <div className={styles.selector}>
            <div className={styles.title}>Theatre</div>
            <Selector
              singleSelect
              list={THEATRE_CRITERIA_MENU_LIST.slice(2).map((item) => ({
                ...item,
                value: item.label,
              }))}
              onSelect={([selected]) => {
                this.setState({
                  selectedCriteriaType: selected,
                  selectedCriteriaValue: null,
                  errors: [],
                });
                //SLATE-1435 Dec11 Anushree:- calling parent method to reset the errors to empty array on list item reselection
                this.props.handleErrorReset();
              }}
              selectedList={[{ ...selectedCriteriaType, value: selectedCriteriaType.label }]}
              showLabelInButton={false}
              placeHolder="Select Criteria"
              label=""
              usePortal={false}
            />
          </div>
          <div className={styles.criteriaInput}>{this.renderInputByCriteriaType()}</div>
          <div className={styles.actions}>
            <LocalizedButton
              id="include"
              className={classNames(styles.button, styles.include)}
              iconName="AddIcon"
              disabled={
                targetGroup.status === "Active" ||
                isEmpty(selectedCriteriaValue) ||
                !this.validateSelectedCriteriaValue()
              }
              onClick={() => this.handleCriteriaListUpdate("inclusions")}
              text="Button.include"
            />
            <LocalizedButton
              id="exclude"
              className={classNames(styles.button, styles.exclude)}
              iconName="CancelIcon"
              disabled={
                targetGroup.status === "Active" ||
                isEmpty(selectedCriteriaValue) ||
                !this.validateSelectedCriteriaValue()
              }
              onClick={() => this.handleCriteriaListUpdate("exclusions")}
              text="Button.exclude"
            />
          </div>
        </div>
        <SelectByIdPanel
          isOpen={isPanelOpen}
          isLoading={isPanelLoading}
          onClose={this.handlePanelClose}
          selectedCriteriaType={selectedCriteriaType}
          onCriteriaChange={this.handleCriteriaChange}
          invalidIds={invalidIds}
          validIds={selectionData}
          header={"RightPanelHeader.selectById"}
          getSelectedIds={this.updateSelectedIds}
          primaryButtonProps={[
            selectedIdList.length > 0
              ? {
                  text: "Button.verify",
                  onClick: this.getSelectionData,
                  className: styles.include,
                  icon: <AddFilledIcon />,
                  disabled: selectedIdList.length === 0,
                }
              : {
                  text: invalidIds.length > 0 ? "Button.ignoreAndProceed" : "Button.include",
                  onClick: () => this.updateSelectedIdsByCriteriaType("inclusions"),
                  className: styles.include,
                  icon: <AddFilledIcon />,
                  disabled:
                    (invalidIds.length > 0 && selectionData.length === 0) ||
                    selectionData.length === 0,
                },
          ]}
          secondaryButtonProps={{
            text: "Button.cancel",
            onClick: () => this.handleSelectByIdPanelClose(),
            className: styles.disabledBtn,
            icon: <CancelIcon />,
            disabled: false,
          }}
        />
        {/* 
          This component is used to upload CSV for selecting screens or theatres
        */}
        <UploadScreensCSV
          isOpen={isUploadCsvPanelOpen}
          onClose={this.handleUploadPanelClose}
          userData={this.props.userData}
          uploadCSVFile={(file) => this.uploadCSVFile(file)}
          matchedTheatreData={matchedTheatreData}
          unmatchedTheatreData={unmatchedTheatreData}
          header={"RightPanelHeader.selectTheatresByCSV"}
          title={"Button.selectCSVFile"}
          getSelectedIds={this.updateSelectedIds}
          primaryButtonProps={{
            text: "Button.include",
            onClick: () => this.updateIdsFromCSVToCriteriaList("inclusions"),
            className: styles.include,
            icon: <AddFilledIcon />,
            disabled: matchedTheatreData.length === 0,
          }}
          secondaryButtonProps={{
            text: "Button.cancelSelection",
            onClick: this.handleUploadPanelClose,
            className: styles.disabledBtn,
            icon: <CancelIcon />,
            disabled: false,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.userData,
  (userData) => userData.user
);

export default connect(mapStateToProps, bindDispatch)(TheatreBasedInput);
