import { Tag } from "@blueprintjs/core";
import classNames from "classnames";
import lodash, { flatten } from "lodash";
import moment from "moment";
import { BUTTON_TYPE } from "prefab";
import React from "react";
import styles from "../../../../../src/styles/Reports/ReportCard.module.scss";
import { downloadReport, getCore } from "../../../../api";
import { TAG_RENDER_TYPE, TAG_RENDER_TYPE_MAP, TAG_TYPE } from "../../../../constants";
import {
  formatDateTime,
  getTagDisplayName,
  getTagTypeDisplayName,
  showToast,
} from "../../../../utils";
import FilterSection from "../../../common/Filters/FilterSection";
import LocalizedButton from "../../../common/LocalizedButton";
import Popup from "../../../common/Popup";
import RightPanel from "../../../common/RightPanel";
import ReportFilters from "./ReportFilters";

// const { LockedIcon, MultipleUsersIcon } = Icons;

export default class ReportCard extends React.Component {
  state = {
    timePeriod: {
      fromDate: null,
      toDate: null,
    },
    conditions: [
      {
        type: "is Between",
        id: 1,
      },
    ],
    filters: [],
    filterTypes: [],
    mandatoryFilterTypes: [],
    conditionType: "",
    loaderValue: 0,
    isFormEdited: false,
    isDownloadProcessStarted: false,
    isOpen: false,
    error: {
      timePeriodError: false,
      conditionError: false,
      generationFail: false,
    },
  };
  componentDidMount() {
    const {
      report: { filters, filterTypes },
    } = this.props;
    //partition the filter types based on the isRequired property to get mandatory filter types
    const [mandatoryFilterTypes, _filterTypes] = lodash.partition(filterTypes, (f) => f.isRequired);
    //set the state
    this.setState({ filters, filterTypes: _filterTypes, mandatoryFilterTypes });
  }

  /**
   * Closes the report card and resets the state.
   */
  onClose = () => {
    //reset the state
    if (this.state.loaderValue !== 100) {
      this.setState({
        isDownloadProcessStarted: false,
        error: { timePeriodError: false, conditionError: false },
      });
      return;
    }
    //close the popup
    this.setState({
      isDownloadProcessStarted: false,
      loaderValue: 0,
      error: { timePeriodError: false, conditionError: false },
    });
  };

  // Close the panel
  closePanel = () => this.setState({ isOpen: false });

  // Toggle the panel
  togglePanel = () => this.setState((state) => ({ isOpen: !state.isOpen }));

  onFilterSelect = (e) => {
    const { filters } = this.state;
    if (!e) return;

    //check if the filter is already selected
    const index = filters.findIndex((f) => f.type === e.type);

    //check if the filter is a date range
    switch (TAG_RENDER_TYPE_MAP[e.type]) {
      case TAG_TYPE.DATE_RANGE: {
        //check if the filter is already selected
        if (this.DateRangeInDays(e.from, e.to) > 61) {
          showToast("Date range should not exceed 60 days", false, 5000);
          return;
        }
        if (index >= 0) filters.splice(index, 1);
        else filters.push(e);
        break;
      }
      default: {
        //check if the filter is already selected
        if (e.isSingleSelect) {
          //check if the filter is already selected
          if (index >= 0) {
            if (filters[index].id === e.id) filters.splice(index, 1);
            else filters[index] = { ...e };
            //add the filter to the list
          } else filters.push(e);
        } else {
          //check if the filter is already selected
          const removeFilterFromIndex = filters.findIndex((f) => f.id === e.id);
          //remove the filter if it is already selected
          if (removeFilterFromIndex >= 0) filters.splice(removeFilterFromIndex, 1);
          //add the filter to the list
          else filters.push(e);
        }
        break;
      }
    }
    this.setState({ filters });
  };

  /**
   * Clears the filter based on the specified tag type.
   * @param {string} tagType - The type of tag to be cleared.
   */
  onFilterClear = (tagType) => {
    const { filters } = this.state;
    //remove the filter based on the tag type
    this.setState({
      filters: filters.filter((f) => !flatten([tagType]).includes(f.type)),
    });
  };

  DateRangeInDays = (fromDate, toDate) => {
    //return false if the date range is empty
    if (!fromDate || !toDate) return false;
    //get the date range in days
    const dateRange = moment(toDate).diff(moment(fromDate), "days");
    //return the date range
    return dateRange;
  };

  /**
   * Renders a progress bar based on the loaderValue and error state.
   * @returns {JSX.Element} The rendered progress bar component.
   */
  progressBar = () => {
    const { loaderValue, error } = this.state;

    //return the progress bar
    return (
      <div className={styles.progressBar}>
        <div
          className={styles.progress}
          style={{
            width: `${loaderValue}%`,
            background: `${
              loaderValue !== 100 ? "#0a5ecc" : error.generationFail ? "#f11717" : "#0acc31"
            }`,
          }}
        ></div>
      </div>
    );
  };

  /**
   * Returns the filtered body of the report card.
   * @returns {JSX.Element} The filtered body of the report card.
   */
  filterBody = () => {
    const { filters, filterTypes } = this.state;
    return (
      <>
        <RightPanel
          isOpen={this.state.isOpen}
          onClose={this.closePanel}
          header="RightPanelHeader.filters"
          showFooter
          primaryButtonProps={[
            {
              text: "Button.apply",
              onClick: this.closePanel,
            },
          ]}
        >
          {filterTypes.map((f) => (
            <FilterSection
              {...this.props}
              tagType={f.type}
              key={f.type}
              selected={filters.filter((t) => flatten([f.type]).includes(t.type))}
              onFilterClear={() => this.onFilterClear(f.type)}
              onFilterChange={this.onFilterSelect}
              isSingleInput={true}
            />
          ))}
        </RightPanel>
      </>
    );
  };

  /**
   * Generates a report and initiates the download process.
   * @returns {Promise<void>} A promise that resolves when the report generation and download process is complete.
   */
  generateReportNow = async () => {
    const { filters, mandatoryFilterTypes: mandatoryFilters } = this.state;

    //get mandatory filter in string
    const mandatoryFilterInString = mandatoryFilters.map((f) => f.type).join(" , ");
    //check if all mandatory filters are selected
    const isAllMandatoryFiltersSelected = mandatoryFilters.every((f) =>
      filters.some((s) => s.type === f.type)
    );
    //show toast if mandatory filters are not selected
    if (!isAllMandatoryFiltersSelected) {
      showToast(`Please select ${mandatoryFilterInString} filter`, false, 5000);
      return;
    }
    //show toast if report generation is in progress
    if (this.state.loaderValue < 100 && this.state.loaderValue > 0) {
      showToast("Report generation is in progress. Please wait for a while", false, 5000);
      return;
    }
    //reset loader value if it is 100
    if (this.state.loaderValue === 100) {
      this.setState({ loaderValue: 0 });
    }
    const {
      report: { id },
    } = this.props;
    //start the download process
    this.setState({ isDownloadProcessStarted: true });
    //start the loader
    const interval = setInterval(() => {
      if (this.state.loaderValue >= 40) {
        //stop the loader
        this.setState({ loaderValue: 40 });
        clearInterval(interval);
        //start the loader again with a different interval with slower speed
        const interval2 = setInterval(() => {
          if (this.state.loaderValue >= 90) {
            //stop the loader
            clearInterval(interval2);
          }
          this.setState({ loaderValue: this.state.loaderValue + 0.5 });
        }, 2000);
        return;
      }
      this.setState({ loaderValue: this.state.loaderValue + 2 });
    }, 1000);

    //download the report
    try {
      const report = await downloadReport(id, filters);
      //create a url for the report
      const url = window.URL.createObjectURL(new Blob([report.data]));
      //create a link element and download the report
      const link = document.createElement("a");
      //set the href and download attribute
      link.href = url;
      //set the download attribute
      link.download = report.headers["report-filename"];
      //append the link element to the body
      document.body.appendChild(link);
      //click the link element
      link.click();
      //remove the link element and revoke the object url
      document.body.removeChild(link);
      //revoke the object url
      URL.revokeObjectURL(url);
      //stop the loader and show the success message
      clearInterval(interval);
      this.setState({ loaderValue: 100 });
      setTimeout(() => {
        //close the popup after 2 seconds
        this.onClose();
      }, 2000);
    } catch (error) {
      //stop the loader and show the error message
      clearInterval(interval);
      this.setState({ loaderValue: 100, error: { generationFail: true } });
      showToast("Error: Data not available for the selected date range.", false, 5000);
    }
  };

  /**
   * Auto fills the filter data based on the selected filter type and value.
   * @param {Array} filterType - The filter type and its corresponding selected value.
   * @returns {void}
   */
  async autoFillFilterData([filterType, [selected]]) {
    //get the filters from the state
    const { filters } = this.state;
    //check if the filter type has autofill and the selected value is not empty
    if ((filterType && !filterType.hasAutoFill) || !selected) return;
    const {
      autoFillFilter: { endPoint, filterType: type },
    } = filterType;
    //check if the filter type is already selected
    if (filters.some((f) => f.type === type)) return;
    //get the core instance
    const core = getCore();
    // set the id from which the data is to be fetched
    let id = "";
    switch (type) {
      case TAG_TYPE.PLAYBACK_DATE: {
        ({ id } = selected);
        break;
      }
      case TAG_TYPE.DATE_TIME_RANGE: {
        ({ id } = selected);
        break;
      }
      case TAG_TYPE.THEATRE: {
        ({ id } = selected.context.theatre);
        break;
      }
      default:
        return;
    }
    //get the data from the endpoint
    const { data } = await core.get(endPoint.replace("{id}", id));
    //check if the data is empty
    switch (TAG_RENDER_TYPE_MAP[type]) {
      case TAG_RENDER_TYPE.DATE_RANGE: {
        const { fromDate, toDate } = data.validity;
        const DateRange = {
          displayName: `${formatDateTime(new Date(fromDate), "MMM DD, YYYY")}-${formatDateTime(
            new Date(toDate),
            "MMM DD, YYYY"
          )}`,
          from: new Date(fromDate),
          to: new Date(toDate),
          type: type,
          id: type,
        };
        this.onFilterSelect(DateRange);
        return;
      }
      case TAG_RENDER_TYPE.DATE_TIME_RANGE: {
        const { fromDate, toDate } = data.validity;

        //! Added this for Authorized Report
        // Set both dates to 2:30 AM
        const fromDateTime = moment(fromDate).hours(2).minutes(30).seconds(0).milliseconds(0);

        // For end date, first add 1 day to campaign end date
        const campaignEndPlusOne = moment(toDate)
          .add(1, "days")
          .hours(2)
          .minutes(30)
          .seconds(0)
          .milliseconds(0);

        // Get today + 1 at 2:30 AM
        const todayPlusOne = moment()
          .add(1, "days")
          .hours(2)
          .minutes(30)
          .seconds(0)
          .milliseconds(0);

        // Use the earlier of campaign end + 1 or today + 1
        const endDateTime = moment.min(campaignEndPlusOne, todayPlusOne);

        const DateTimeRange = {
          displayName: `${formatDateTime(
            fromDateTime.toDate(),
            "MMM DD, YYYY hh:mm A"
          )}-${formatDateTime(endDateTime.toDate(), "MMM DD, YYYY hh:mm A")}`,
          from: fromDateTime.toDate(),
          to: endDateTime.toDate(),
          type: type,
          id: type,
        };
        this.onFilterSelect(DateTimeRange);
        return;
      }
      case TAG_RENDER_TYPE.SEARCH: {
        this.onFilterSelect(data);
        return;
      }
      default:
        return;
    }
  }

  render() {
    const {
      isDownloadProcessStarted,
      loaderValue,
      error,
      mandatoryFilterTypes,
      filters,
    } = this.state;
    const {
      report: { name, description },
    } = this.props;

    const parts = description.split("*");

    return (
      <div className={styles.cardContainer}>
        <div className={styles.cardHeader}>
          <div className={styles.title}>
            <div className={styles.cardTitle}>{name}</div>
          </div>
          {/* <div className={styles.titleTypeContainer}>
            {reportType !== "Private" ? (
              <div className={styles.titleType}>
                <MultipleUsersIcon className={styles.iconColor} />
                <div>{reportType}</div>
              </div>
            ) : (
              <div className={styles.titleType}>
                <LockedIcon className={styles.iconColor} />
                <div>{reportType}</div>
              </div>
            )}
          </div> */}
        </div>
        <div className={styles.description}>
          {parts[0]}
          {parts.length > 1 && (
            <>
              <br />
              <span className={styles.secondLine}>{`*${parts[1]}`}</span>
            </>
          )}
        </div>
        {/* <MandatoryFilters mandatoryFilterTypes={mandatoryFilterTypes} /> */}
        <div className={styles.mandatoryFiltersContainer}>
          {mandatoryFilterTypes.map((f) => (
            <ReportFilters
              {...this.props}
              tagType={f.type}
              key={f.type}
              selected={filters.filter((t) => flatten([f.type]).includes(t.type))}
              onFilterClear={() => this.onFilterClear(f.type)}
              autoFillFilterData={(selected) => this.autoFillFilterData(selected)}
              filterType={f}
              onFilterChange={this.onFilterSelect}
              isSingleInput={true}
              filters={filters}
            />
          ))}
        </div>
        <div className={styles.filterContainer}>
          <LocalizedButton
            buttonType={BUTTON_TYPE}
            className={styles.buttonStyleActive}
            iconName="FilterAdjustIcon"
            text="Add Filters"
            onClick={this.togglePanel}
          />
          <div className={styles.filterChips}>
            {filters.map((filter) => (
              <Tag
                key={filter.id}
                className={classNames(styles.primary, styles.chip)}
                minimal
                onRemove={() => this.onFilterSelect(filter)}
              >
                {getTagTypeDisplayName(filter.type)}: {getTagDisplayName(filter)}
              </Tag>
            ))}
            {filters.length > 0 && (
              <Tag
                key="CLEAR-ALL"
                minimal
                className={classNames(styles.danger, styles.chip)}
                interactive
                onClick={() => this.setState({ filters: [] })}
              >
                Clear
              </Tag>
            )}
          </div>
        </div>
        {this.filterBody()}
        <div className={styles.dividerLine}></div>
        <LocalizedButton
          buttonType={BUTTON_TYPE.PRIMARY}
          className={styles.buttonStyle}
          text="Generate Report"
          onClick={this.generateReportNow}
        />
        {isDownloadProcessStarted && (
          <Popup
            isOpen={isDownloadProcessStarted}
            onClose={this.onClose}
            title={
              loaderValue !== 100
                ? "Generating Report"
                : error.generationFail
                ? "Report Generation Failed"
                : "Report Generated"
            }
            body={this.progressBar()}
            cancelButtonText=""
            confirmButtonText=""
          />
        )}
      </div>
    );
  }
}
