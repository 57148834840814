import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Classes } from "@blueprintjs/core";
import styles from "../../styles/InfoBlock.module.scss";
import { Checked, Copy } from "./LucideIcons";

const TextWithLabel = ({ loading, label, text, className, canCopy = false }) => {
  const [copied, setCopied] = useState(false);
  const classes = classNames({
    "col-12": !loading,
    "col-4": loading,
    [Classes.SKELETON]: loading,
  });

  const copyToClipboard = () => {
    // Get the content container
    const contentElement = document.getElementById(label);

    if (contentElement) {
      // Get only the text content (no HTML tags)
      const { textContent } = contentElement;

      // Copy to clipboard using the Clipboard API
      navigator.clipboard.writeText(textContent || "").then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    }
  };
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div
        style={{ display: "flex", justifyContent: "flex-start", width: "max-content", gap: "10px" }}
      >
        <div className={classNames(styles.label, classes)}>{label}</div>
        {canCopy && (
          <div style={{ paddingTop: "2px" }} onClick={copyToClipboard}>
            {copied ? <Checked /> : <Copy />}
          </div>
        )}
      </div>
      <div className={classNames("col-12", styles.textValue, classes)} id={label}>
        {text}
      </div>
    </div>
  );
};

TextWithLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
};

export default TextWithLabel;
