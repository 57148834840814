/**
 * This file contains the actions for creating and updating campaigns in the store.
 * It includes functions for updating campaign details, target groups, media, and performing CRUD operations.
 * The actions are asynchronous and make API calls to fetch and save data.
 * The file also includes utility functions for modifying target group data and getting target groups and media by ID.
 */
import {
  getCampaign as getCampaignApi,
  getCampaignTargetGroups as getCampaignTargetGroupsApi,
  getCampaignMedia as getCampaignMediaApi,
  getTargetGroup as getTargetGroupApi,
  getMedia as getMediaApi,
  getTargetGroupSummary,
  getTheatresByCriteria,
  createOrUpdateCampaign as createOrUpdateCampaignApi,
  createOrUpdateTargetGroup as createOrUpdateTargetGroupApi,
  createOrUpdateMedia as createOrUpdateMediaApi,
  deleteCampaign as deleteCampaignApi,
  deleteTargetGroup as deleteTargetGroupApi,
  deleteMedia as deleteMediaApi,
  getTargetGroupTheatres,
  saveCampaignStep,
  targetGroupSplit as splitTargetGroupApi,
  getSpotAvailability as getSpotAvailabilityApi,
} from "../../api";
import * as types from "../types/campaignCreate";
import * as constants from "../../constants";
import { showToast } from "../../utils";

const actionCreator = (type, payload) => {
  return { type, payload };
};

// Update Action: Update Campaign Details
const ActiveStep = (payload) => {
  return { type: types.CAMPAIGN_STEPS, payload: payload };
};

// Update Action: Update Campaign Details
const updateNewCampaignDetails = (payload) => {
  return { type: types.NEW_CAMPAIGN_DETAILS, payload: payload };
};

// Update Action: Update Campaign Target Group List
const updateNewCampaignTargetGroups = (payload) => {
  return { type: types.NEW_CAMPAIGN_TARGET_GROUPS, payload: payload };
};

// Update Action: Update Campaign Media List
const updateCampaignMediaList = (payload) => {
  return { type: types.NEW_CAMPAIGN_MEDIA_LIST, payload: payload };
};

// Update Action: Update Target group action ["list", "create", "edit", "duplicate"]
const updateTargetGroupAction = (payload) => {
  return { type: types.TARGET_GROUP_ACTION, payload };
};

// Update Action: Update Media action ["list", "create", "edit", "duplicate"]
const updateMediaAction = (payload) => {
  return { type: types.MEDIA_ACTION, payload };
};

// Update Action: Update Media action ["list", "create", "edit", "duplicate"]
const resetCampaignCreateError = (payload = null) => {
  return { type: types.IS_ERROR, payload };
};

// Update Action: Update Campaign Target Group
const updateNewCampaignTargetGroup = (targetGroup, type = "edit") => {
  return async (dispatch) => {
    const targetGroupData = targetGroup;
    if (type === "duplicate") {
      delete targetGroupData.id;
      targetGroupData.name = "";
    }
    dispatch(actionCreator(types.NEW_CAMPAIGN_TARGET_GROUP, targetGroupData));
    dispatch(updateTargetGroupAction(type));
  };
};
const updateActiveStep = (payload) => {
  return async (dispatch) => {
    dispatch(ActiveStep(payload.campaignStep));
    if (typeof payload.campaignStep === "number")
      payload.campaignStep = payload.campaignStep.toString();
    await saveCampaignStep(payload);
  };
};

// Update Action: Update Campaign Media
const updateNewCampaignMedia = (media, type = "edit") => {
  return async (dispatch) => {
    const mediaData = media;
    if (type === "duplicate") {
      delete mediaData.id;
      mediaData.name = "";
    }
    dispatch(actionCreator(types.NEW_CAMPAIGN_MEDIA, mediaData));
    dispatch(updateMediaAction(type));
  };
};

const updateTheatresAndScreens = (payload) => {
  return { type: types.CRITERIA_THEATRES, payload };
};

// Create new campaign or update
const createOrUpdateCampaign = (campaignData, saveData = createOrUpdateCampaignApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, true));
      const { data } = await saveData(campaignData);
      if (campaignData.id) {
        showToast("Toast.campaignUpdatedSuccessfully");
      } else {
        showToast("Toast.campaignCreatedSuccessfully");
      }
      dispatch(updateNewCampaignDetails(data));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
    } catch (error) {
      showToast(error.response.data?.message, false);
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
    }
  };
};

//SLATE-1436 Dec14 Anushree:- function to transform auditorium dimensions object to individual dimension objects
const transformDimensions = (items) =>
  items.flatMap((item) =>
    item.type === "auditoriumDimensions"
      ? ["Height", "Length", "Width"].map((dimension) => ({
          type: `auditorium${dimension}`,
          typeDisplayName: `Auditorium ${dimension}`,
          targetGroupType: item.targetGroupType,
          tags: item.tags,
          isRange: item.isRange,
          rangeFrom: item[`auditorium${dimension}`].rangeFrom,
          rangeTo: item[`auditorium${dimension}`].rangeTo,
        }))
      : item
  );
//SLATE-1436 Dec14 Anushre:- function to revert individual dimension objects to auditorium dimensions object
const revertDimensions = (items) => {
  const result = [];
  let temp = { tags: "" };
  items.forEach((item) => {
    if (["auditoriumHeight", "auditoriumLength", "auditoriumWidth"].includes(item.type)) {
      temp.type = "auditoriumDimensions";
      temp.targetGroupType = "theatre";
      temp.tags += item.tags;
      temp.isRange = item.isRange;
      temp.typeDisplayName = "Auditorium Dimensions";
      temp[item.type] = { rangeFrom: item.rangeFrom, rangeTo: item.rangeTo };
      if (Object.keys(temp).length === 8) {
        result.push(temp);
        temp = {};
      }
    } else {
      result.push(item);
    }
  });
  return result;
};
// Create new target group or update
const createOrUpdateTargetGroup = (
  campaignId,
  tgData,
  actionType,
  saveData = createOrUpdateTargetGroupApi
) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, true));
      //SLATE-1436 Dec14 Anushree:- converting auditorium data object to individual dimension objects
      tgData.criteria.inclusions = transformDimensions(tgData.criteria.inclusions);
      tgData.criteria.exclusions = transformDimensions(tgData.criteria.exclusions);
      const { data } = await saveData(campaignId, tgData);
      if (tgData.id) {
        showToast("Toast.targetGroupUpdatedSuccessfully");
      } else {
        showToast("Toast.targetGroupCreatedSuccessfully");
      }
      await dispatch(getNewCampaignTargetGroups({ campaignId }));
      await dispatch(updateNewCampaignTargetGroup(data, actionType));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    } catch (error) {
      tgData.criteria.inclusions = revertDimensions(tgData.criteria.inclusions);
      tgData.criteria.exclusions = revertDimensions(tgData.criteria.exclusions);
      showToast(error.response.data.message, false);
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    }
  };
};

// Create new Media or Update
const createOrUpdateMedia = (
  campaignId,
  targetGroupId,
  mediaData,
  actionType,
  saveData = createOrUpdateMediaApi
) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_MEDIA_LOADING, true));
      const { data } = await saveData(campaignId, targetGroupId, mediaData);
      if (mediaData.id) {
        showToast("Toast.mediaUpdatedSuccessfully");
      } else {
        showToast("Toast.mediaCreatedSuccessfully");
      }
      await dispatch(getNewCampaignMediaList({ campaignId, targetGroupId }));
      await dispatch(updateNewCampaignMedia(data, actionType));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));
      return data.message || "Operation successful";
    } catch (error) {
      if (error.response.status !== 409) {
        showToast(error.response.data.message, false);
      }
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));

      return error.response ? error.response.data.message : "An unexpected error occurred";
    }
  };
};

// Get campaign details
const getNewCampaignDetails = (params = {}, fetchData = getCampaignApi) => {
  const { campaignId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, true));
      let campaignData = {};
      if (campaignId) {
        const { data } = await fetchData(campaignId);
        campaignData = data;
      }
      dispatch(updateNewCampaignDetails(campaignData));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
    } catch (error) {
      showToast(error.response.data.message, false);
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
    }
  };
};

// Get Campaign Target Group List
const getNewCampaignTargetGroups = (
  params = {},
  filters = [],
  getList = getCampaignTargetGroupsApi
) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc", campaignId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, true));
      const { data } = await getList(campaignId, ps, (page - 1) * ps, sort, filters);
      //ModifyTargetGroupApiData function is called to modify the targetGroups data
      const modifiedData = data.data.map((item) => {
        return modifyTargetGroupApiData(item);
      });
      const newData = { ...data, data: modifiedData };
      dispatch(updateNewCampaignTargetGroups(newData));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    }
  };
};

// Get New Campaign Media List
const getNewCampaignMediaList = (params = {}, filters = [], getList = getCampaignMediaApi) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "createdAt:asc", campaignId } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_MEDIA_LOADING, true));
      const { data } = await getList(campaignId, ps, (page - 1) * ps, sort, filters);
      dispatch(updateCampaignMediaList(data));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));
    }
  };
};

// TODO: Update once api is completed
const deleteCampaign = (id, removeData = deleteCampaignApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, true));
      const response = await removeData(id);
      showToast("Toast.campaignDeletedSuccessfully");
      dispatch(updateNewCampaignDetails(null));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
      return { success: true, data: response };
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_CAMPAIGN_LOADING, false));
      showToast("Toast.campaignDeleteUnsuccessfull", false);
      return { success: false, data: error.message };
    }
  };
};

// TODO: Update once api is completed
const deleteTargetGroup = (params, id, removeData = deleteTargetGroupApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, true));
      await removeData(id);
      showToast("Toast.targetGroupDeletedSuccessfully");
      await dispatch(getNewCampaignTargetGroups(params));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    } catch (error) {
      showToast(error.response.data.message, false);
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    }
  };
};

// TODO: Update once api is completed
const deleteMedia = (params, id, removeData = deleteMediaApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_MEDIA_LOADING, true));
      await removeData(id);
      showToast("Toast.mediaDeletedSuccessfully");
      await dispatch(getNewCampaignMediaList(params));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_MEDIA_LOADING, false));
    }
  };
};

//Split Target Group
const splitTargetGroup = (params, id, data) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, true));
      await splitTargetGroupApi(id, data);
      showToast("Target Group Split Successfully");
      await dispatch(getNewCampaignTargetGroups(params));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    } catch (error) {
      showToast(error.message, false);
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TARGET_GROUP_LOADING, false));
    }
  };
};

const modifyTargetGroupApiData = (targetGroup) => {
  const {
    criteria: { inclusions, exclusions },
  } = targetGroup;

  let _inclusions =
    (Array.isArray(inclusions) &&
      inclusions.map((item) => {
        if (!item.name && item.locationInfo) item["name"] = item.locationInfo.name;
        //SLATE-1445 Dec15 Anushree:- if targetGroupType is theatre and tagsInfos is present then displayName is used as name
        //if (!item.name && item.type === "theatreTypes")
        //   item["name"] = item.tagsInfos[0].displayName;
        if (item.targetGroupType === "theatre") {
          if (!item.name && item?.tagsInfos && !item?.isRange)
            item["name"] = item?.tagsInfos[0].displayName;
          if (item?.isRange) {
            item["tags"] = [`${item.type}-${item.rangeFrom}-${item.rangeTo}`];
          }
        }
        return item;
      })) ||
    [];

  let _exclusions =
    (Array.isArray(exclusions) &&
      exclusions.map((item) => {
        if (!item.name && item.locationInfo) item["name"] = item.locationInfo.name;
        if (item.targetGroupType === "theatre") {
          if (!item.name && item?.tagsInfos && !item?.isRange)
            item["name"] = item?.tagsInfos[0].displayName;
          if (item?.isRange) {
            item["tags"] = [`${item.type}-${item.rangeFrom}-${item.rangeTo}`];
          }
        }
        return item;
      })) ||
    [];

  //SLATE-1436 Dec14 Anushree:- converting individual dimension objects to auditorium dimensions object
  _inclusions = revertDimensions(_inclusions);
  _exclusions = revertDimensions(_exclusions);
  targetGroup.criteria = { inclusions: _inclusions, exclusions: _exclusions };
  return targetGroup;
};

// Get Target Group by Id
const getTargetGroupById = (targetGroupId, actionType, getData = getTargetGroupApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TARGET_GROUP_DETAILS_LOADING, true));
      const { data: _targetGroup } = await getData(targetGroupId);
      const targetGroup = { ...modifyTargetGroupApiData(_targetGroup) };
      dispatch(updateNewCampaignTargetGroup(targetGroup, actionType));
      dispatch(actionCreator(types.IS_TARGET_GROUP_DETAILS_LOADING, false));
      return targetGroup;
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TARGET_GROUP_DETAILS_LOADING, false));
    }
  };
};

// Get Target Group Summary by Id
const getTargetGroupSummaryById = (targetGroupId, actionType, getData = getTargetGroupSummary) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_TARGET_GROUP_DETAILS_LOADING, true));
      const { data: _targetGroup } = await getTargetGroupApi(targetGroupId);
      const { data: _targetGroupSummary } = await getData(targetGroupId);
      const targetGroup = { ...modifyTargetGroupApiData(_targetGroup) };
      targetGroup.summary = _targetGroupSummary;

      dispatch(updateNewCampaignTargetGroup(targetGroup, actionType));
      dispatch(actionCreator(types.IS_TARGET_GROUP_DETAILS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_TARGET_GROUP_DETAILS_LOADING, false));
    }
  };
};

// Get Media by Id
const getMediaById = (id, actionType, getData = getMediaApi) => {
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_MEDIA_DETAILS_LOADING, true));
      const { data: media } = await getData(id);
      dispatch(updateNewCampaignMedia(media, actionType));
      dispatch(actionCreator(types.IS_MEDIA_DETAILS_LOADING, false));
      return media;
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_MEDIA_DETAILS_LOADING, false));
    }
  };
};

// TODO: Update once api is completed
//function to get theatres by criteria
const getTargetGroupTheatresByCriteria = (params) => {
  //SLATE-1404 Nov29 Anushree:- params need to be passed to getTheatresByCriteria
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc", filters = [] } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      //const { data } = await getTheatresByCriteria(10, 0, "name:asc", []);
      //SLATE-1449 Dec19 Anushree:- getTargetGroupTheatres api is called instead of getTheatresByCriteria
      const { data } = await getTheatresByCriteria(params, ps, (page - 1) * ps, sort, filters);
      // const { data } = await getTargetGroupTheatres(params.id, ps, (page - 1) * ps, sort, filters);
      dispatch(updateTheatresAndScreens(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};
const getTargetGroupTheatresByTargetGroupId = (params) => {
  //SLATE-1404 Nov29 Anushree:- params need to be passed to getTheatresByCriteria
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc", filters = [] } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      //const { data } = await getTheatresByCriteria(10, 0, "name:asc", []);
      //SLATE-1449 Dec19 Anushree:- getTargetGroupTheatres api is called instead of getTheatresByCriteria
      // const { data } = await getTheatresByCriteria(params, ps, (page - 1) * ps, sort, filters);
      const { data } = await getSpotAvailabilityApi(params.id, ps, (page - 1) * ps, sort, filters);
      dispatch(updateTheatresAndScreens(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

//function to get theatres by id
const getTargetGroupTheatresById = (params) => {
  const { page = 1, ps = constants.DEFAULT_PAGE_SIZE, sort = "name:asc", filters = [] } = params;
  return async (dispatch) => {
    try {
      dispatch(actionCreator(types.IS_LOADING, true));
      const { data } = await getTargetGroupTheatres(params.id, ps, (page - 1) * ps, sort, filters);
      dispatch(updateTheatresAndScreens(data));
      dispatch(actionCreator(types.IS_LOADING, false));
    } catch (error) {
      dispatch(actionCreator(types.IS_ERROR, error.message));
      dispatch(actionCreator(types.IS_LOADING, false));
    }
  };
};

export {
  getNewCampaignTargetGroups,
  getTargetGroupById,
  getTargetGroupSummaryById,
  updateNewCampaignTargetGroup,
  updateTargetGroupAction,
  deleteTargetGroup,
  deleteMedia,
  deleteCampaign,
  getTargetGroupTheatresByCriteria,
  updateMediaAction,
  updateNewCampaignMedia,
  getMediaById,
  getNewCampaignMediaList,
  getNewCampaignDetails,
  createOrUpdateCampaign,
  createOrUpdateTargetGroup,
  createOrUpdateMedia,
  updateActiveStep,
  ActiveStep,
  resetCampaignCreateError,
  getTargetGroupTheatresById,
  getTargetGroupTheatresByTargetGroupId,
  modifyTargetGroupApiData,
  splitTargetGroup,
};
