import React, { Component } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import { Icons } from "prefab";
import searchStyles from "../../styles/Search.module.scss";
import spinnerStyles from "../../styles/Spinner.module.scss";
import styles from "../../styles/Suggestion.module.scss";
import customMenuStyles from "../../styles/CustomMenu.module.scss";
import BPSuggest from "./BPSuggest";
import { CustomMenuItem } from ".";

const { SearchIcon } = Icons;
/**
 * This expects func. parseResults to return array of objects it format as below
 *  {
 *    id: COMPARE_KEY,
 *    name: TEXT_KEY,
 *    tag: SUBTEXT_KEY,
 *    ...other properties
 *  }
 *
 *  TODO: Fix the behaviour, renders older results if opened for the next time
 */
class ApiSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchResults: [],
      loading: false,
      query: props.query || "",
      selectedItem: props.selectedItem || null,
    };
  }

  static propTypes = {
    placeholder: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    searchIcon: PropTypes.oneOf(["left", "right", "none"]),
    showSubText: PropTypes.bool,
    parseResults: PropTypes.func.isRequired,
    fetchAction: PropTypes.func.isRequired,
    onEmpty: PropTypes.func,
    resetOnSelect: PropTypes.bool,
    disabled: PropTypes.bool,
    clearSelectOnReset: PropTypes.bool,
    selectedItem: PropTypes.object,
    showListOnClick: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: "Search",
    searchIcon: "right",
    showSubText: false,
    resetOnSelect: false,
    disabled: false,
    clearSelectOnReset: false,
    selectedItem: {},
    showListOnClick: false,
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.query !== this.props.query) {
      this.setState({
        query: this.props.query,
      });
    }
    if (prevProps.selectedItem !== this.props.selectedItem) {
      this.setState({
        selectedItem: this.props.selectedItem,
      });
    }
  };

  // fetchAction call should return a Promise
  search = async (value) => {
    try {
      const { data } = await this.props.fetchAction(value);
      const parsed = this.props.parseResults(data);
      this.setState({
        searchResults: parsed,
        loading: false,
      });
    } catch (error) {
      this.setState({ searchResults: [], loading: false });
    }
  };

  debouncedSearch = debounce(this.search, 600);

  onChange = (value) => {
    const { onEmpty } = this.props;
    if (value === null || value === undefined || value.length === 0) {
      this.setState({
        query: "",
        searchResults: [],
        loading: false,
      });
      if (onEmpty) onEmpty();
      if (this.props.clearSelectOnReset) this.props.onSelect(null);
    } else {
      if (value !== this.state.query)
        this.setState({ query: value, loading: true }, () => this.debouncedSearch(value));
    }
  };

  onSelect = (item) => {
    if (!item || !item.id) return;
    this.props.onSelect(item);
    const { resetOnSelect } = this.props;
    this.setState({
      query: resetOnSelect ? "" : item.name,
      selectedItem: resetOnSelect ? null : item,
    });
  };

  renderMenuItemWithSubText = (text, subText) => (
    <>
      <div className={styles.text}>{text}</div>
      <div className={styles.subText}>{subText}</div>
    </>
  );

  renderMenuItemWithSecondaryText = (data) => (
    <div className={styles.withSecondaryText}>
      <div className={styles.text}>{data.name}</div>
      <div className={styles.secondaryText}>{data.item?.code}</div>
    </div>
  );

  createNewItemFromQuery = (query) => ({
    id: query,
    name: query,
    type: "-",
  });

  createNewItemRenderer = (query, active, handleClick) => {
    return (
      <CustomMenuItem
        icon="add"
        className={`${customMenuStyles.menuItem} create-new-item`}
        text={`Create "${query}"`}
        active={active}
        onClick={handleClick}
        shouldDismissPopover={false}
      />
    );
  };

  handleClick = () => {
    // handle click event
    if (this.props.showListOnClick) {
      this.search("");
    }
  };

  render() {
    const { searchResults, loading, selectedItem, query } = this.state;
    const {
      placeholder,
      searchIcon,
      showSubText,
      allowCreate = false,
      customCreateNewItemRenderer,
      disabled,
      error,
      onFocus,
      onBlur,
      className,
    } = this.props;
    const iconProps = {};
    if (searchIcon === "left")
      iconProps.leftIcon = <SearchIcon className={searchStyles.searchIcon} />;
    else if (searchIcon === "right")
      iconProps.rightElement = <SearchIcon className={searchStyles.searchIcon} />;
    if (loading) iconProps.rightElement = <Spinner className={spinnerStyles.spinner} size={16} />;

    const maybeCreateNewItemFromQuery = allowCreate ? this.createNewItemFromQuery : undefined;
    const maybeCreateNewItemRenderer =
      allowCreate && customCreateNewItemRenderer
        ? customCreateNewItemRenderer
        : allowCreate
        ? this.createNewItemRenderer
        : null;
    return (
      <div onClick={this.handleClick}>
        <BPSuggest
          items={searchResults}
          query={query}
          selectedItem={selectedItem}
          inputProps={{
            ...iconProps,
            placeholder,
            disabled,
            onFocus,
            onBlur,
            className,
          }}
          onItemSelect={this.onSelect}
          itemRenderer={(item, itemProps) => (
            <CustomMenuItem
              onClick={itemProps.handleClick}
              className={classNames(customMenuStyles.menuItem, {
                [styles.menuItemSelected]: selectedItem?.id === item.id,
                [styles.menuItemActive]: itemProps.modifiers.active,
                [styles.menuItemDisabled]: itemProps.modifiers.disabled,
              })}
              key={item.id}
              text={
                showSubText
                  ? this.renderMenuItemWithSubText(item.name, item.tag)
                  : this.renderMenuItemWithSecondaryText(item)
              }
            />
          )}
          inputValueRenderer={(item) => (query ? item.name : "")}
          itemsEqual="id"
          noResults={
            !loading && query?.length > 0 && searchResults?.length === 0 ? (
              <CustomMenuItem
                disabled={true}
                text="No results found"
                className={customMenuStyles.menuItem}
              />
            ) : undefined
          }
          onQueryChange={this.onChange}
          createNewItemFromQuery={maybeCreateNewItemFromQuery}
          createNewItemRenderer={maybeCreateNewItemRenderer}
          error={error}
        />
      </div>
    );
  }
}

export default ApiSearch;
