import classNames from "classnames";
import { Icons } from "prefab";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SlateLogo, SlateLogoSmall } from "../../../assets";
import { COMPANY_STATUS, SCOPES, SCOPE_ACTIONS } from "../../../constants";
import styles from "../../../styles/NavigationBar.module.scss";
import { isSlateAdminCompanyUser } from "../../../utils";
import FooterView from "./FooterView";
import NavMenuAccordion from "./NavMenuAccordion";
import NavMenuItem from "./NavMenuItem";

const {
  InventoryIcon,
  OrganisationIcon,
  PendingApprovalIcon,
  CampaignIcon,
  CompaniesIcon,
  ArchivedMediaIcon,
  ContentIcon,
  TheatreIcon,
  PlaylistIcon,
  PremiumIcon,
  RateCardIcon,
  ListIcon,
  SettingsIcon,
  SupportIcon,
  CalendarIcon,
  ReportsIcon,
} = Icons;

const Menus = [
  {
    name: "PageHeader.settings",
    route: "/settings",
    Icon: SettingsIcon,
    scope: SCOPES.COMPANIES_PREFERENCES,
    onlyInUser: true,
  },
  {
    name: "PageHeader.support",
    route: "/support",
    Icon: SupportIcon,
    // scope: SCOPES.__SELF__ // TODO: Enable after this option is added
  },
];

export class NavigationBar extends Component {
  static propTypes = {
    hasNavigation: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    handlePinned: PropTypes.func,
    handleExpand: PropTypes.func,
    isHovered: PropTypes.bool.isRequired,
  };

  selectNav(isSelected) {
    this.setState({ isSelected });
  }

  getNavCount = (key) => {
    const { navCount } = this.props;
    if (!navCount[key]) return 0;
    return navCount[key];
  };

  isActiveMenu(paths) {
    const { location } = this.props;
    return paths.some((path) => location.pathname && location.pathname.indexOf(path) === 0);
  }

  shouldShowNavigationItems() {
    const {
      userData,
      userData: { company },
    } = this.props;
    const isAdminApp = !!process.env.REACT_APP_IS_ADMIN_APP;
    const isSlateAdminCompany = isSlateAdminCompanyUser(userData);
    const isApprovedCompany = company.status !== COMPANY_STATUS.PENDING;
    return (
      ((isAdminApp && isSlateAdminCompany) || (!isAdminApp && !isSlateAdminCompany)) &&
      isApprovedCompany
    );
  }

  render() {
    const {
      isExpanded,
      handleExpand,
      isHovered,
      handlePinned,
      userData: { user, company },
      location,
    } = this.props;

    if (!user) return null;
    const userName = [user.firstName, user.lastName].filter(Boolean).join(" ");
    const companyName = company.name || company.legalName;
    const isExpandedNav = isExpanded || isHovered;
    const isAdminApp = !!process.env.REACT_APP_IS_ADMIN_APP;

    return (
      <div
        className={classNames("flex flex-column clearfix relative", styles.navContainer, {
          [styles.expandedContainer]: isExpandedNav,
          [styles.adminNavContainer]: isAdminApp,
        })}
        style={{ height: "100vh" }}
      >
        <div onClick={() => handleExpand(false)} className={classNames("flex", styles.navHeader)}>
          <Link to="/dashboard" onClick={() => handleExpand(false)} draggable={false}>
            {isExpandedNav ? <SlateLogo /> : <SlateLogoSmall />}
          </Link>
        </div>
        <div className={styles.navigationBar}>
          {this.shouldShowNavigationItems() && (
            <>
              <NavMenuItem
                handleExpand={handleExpand}
                isSelected={this.isActiveMenu(["/reports"])}
                isExpanded={isExpandedNav}
                name="PageHeader.reports"
                route="/reports"
              />
              <NavMenuAccordion
                name="PageHeader.contentNavHeading"
                isExpanded={isExpandedNav}
                menus={[
                  {
                    name: "PageHeader.content",
                    handleExpand,
                    badge: this.getNavCount("content"),
                    route: "/content",
                    Icon: ContentIcon,
                    isSelected: this.isActiveMenu(["/content"]),
                  },
                  {
                    name: "PageHeader.unmappedCPLs",
                    handleExpand,
                    badge: this.getNavCount("unmappedCPLs"),
                    route: "/cpl_mappings/unmapped",
                    scope: SCOPES.CPL_MAPPINGS,
                    Icon: ListIcon,
                    isSelected: this.isActiveMenu(["/cpl_mappings"]),
                  },
                  {
                    name: "PageHeader.compositions",
                    handleExpand,
                    route: "/compositions",
                    Icon: ContentIcon,
                    isSelected: this.isActiveMenu(["/compositions"]),
                    scope: SCOPES.COMPOSITIONS,
                  },
                  {
                    name: "PageHeader.archivedContent",
                    handleExpand,
                    badge: this.getNavCount("archivedContent"),
                    scope: SCOPES.CONTENT,
                    route: "/archived-content",
                    Icon: ArchivedMediaIcon,
                    isSelected: this.isActiveMenu(["/archived-content"]),
                  },
                ]}
                location={location}
              />
              <NavMenuAccordion
                name="PageHeader.scheduleNavHeading"
                isExpanded={isExpandedNav}
                menus={[
                  {
                    name: "PageHeader.schedules",
                    handleExpand,
                    route: "/schedules",
                    Icon: CalendarIcon,
                    isSelected: this.isActiveMenu(["/schedules"]),
                    // scope: SCOPES.SCHEDULES,
                  },
                ]}
                location={location}
              />
              {/* <NavMenuAccordion
                name="PageHeader.compositionNavHeading"
                isExpanded={isExpandedNav}
                menus={[
                  {
                    name: "PageHeader.compositions",
                    handleExpand,
                    route: "/compositions",
                    Icon: ContentIcon,
                    isSelected: this.isActiveMenu(["/compositions"]),
                    scope: SCOPES.COMPOSITIONS,
                  },
                ]}
                location={location}
              /> */}
              <NavMenuAccordion
                name="PageHeader.campaignsNavHeading"
                isExpanded={isExpandedNav}
                menus={[
                  {
                    name: "PageHeader.campaigns",
                    handleExpand,
                    patch: this.getNavCount("campaigns"),
                    route: "/campaigns",
                    Icon: CampaignIcon,
                    badge: this.getNavCount("campaigns"),
                    isSelected: this.isActiveMenu(["/campaigns"]),
                    scope: SCOPES.CAMPAIGNS,
                  },
                  {
                    name: "PageHeader.campaignRateBias",
                    handleExpand,
                    patch: this.getNavCount("campaigns"),
                    route: "/campaign-bias",
                    Icon: CampaignIcon,
                    badge: this.getNavCount("campaigns"),
                    isSelected: this.isActiveMenu(["/campaign-bias"]),
                    scope: SCOPES.CAMPAIGNS,
                  },
                  {
                    name: "PageHeader.campaignReports",
                    handleExpand,
                    patch: this.getNavCount("campaigns"),
                    route: "/campaign-reports",
                    Icon: CampaignIcon,
                    badge: this.getNavCount("campaigns"),
                    isSelected: this.isActiveMenu(["/campaign-reports"]),
                    // scope: SCOPES.CAMPAIGNS,
                  },
                  {
                    name: "PageHeader.showFailures",
                    handleExpand,
                    patch: this.getNavCount("campaigns"),
                    route: "/show-failures",
                    Icon: ReportsIcon,
                    badge: this.getNavCount("showFailures"),
                    isSelected: this.isActiveMenu(["/show-failures"]),
                    scope: SCOPES.CAMPAIGNS,
                  },
                ]}
                location={location}
              />
              <NavMenuAccordion
                name="PageHeader.inventoryNavHeading"
                isExpanded={isExpandedNav}
                icon={InventoryIcon}
                menus={[
                  {
                    name: "PageHeader.theatresScreens",
                    handleExpand,
                    badge: this.getNavCount("theatres"),
                    route: "/theatres",
                    Icon: TheatreIcon,
                    isSelected: this.isActiveMenu(["/theatres", "/screens"]),
                    scope: SCOPES.INVENTORY,
                  },
                  {
                    name: "PageHeader.playlistTemplates",
                    handleExpand,
                    badge: this.getNavCount("playlistTemplates"),
                    route: "/playlist-templates",
                    Icon: PlaylistIcon,
                    isSelected: this.isActiveMenu(["/playlist-templates"]),
                    scope: SCOPES.PLAYLIST_TEMPLATES,
                  },
                ]}
                location={location}
              />
              <NavMenuAccordion
                name="PageHeader.catalogueNavHeading"
                icon={OrganisationIcon}
                isExpanded={isExpandedNav}
                menus={[
                  {
                    name: "PageHeader.rateCards",
                    handleExpand,
                    badge: this.getNavCount("rateCard"),
                    route: "/rate-card",
                    Icon: RateCardIcon,
                    isSelected: this.isActiveMenu(["/rate-card"]),
                  },
                  {
                    name: "PageHeader.premiums",
                    handleExpand,
                    badge: this.getNavCount("premiums"),
                    route: "/premiums",
                    Icon: PremiumIcon,
                    isSelected: this.isActiveMenu(["/premiums"]),
                  },
                  {
                    name: "PageHeader.brands",
                    handleExpand,
                    badge: this.getNavCount("organizationOrBrand"),
                    route: "/brands/parent-brands",
                    isSelected: this.isActiveMenu(["/brands"]),
                    scope: SCOPES.BRANDS,
                  },
                  {
                    name: "PageHeader.clients",
                    handleExpand,
                    badge: this.getNavCount("advertisers"),
                    route: "/companies/clients",
                    isSelected: this.isActiveMenu(["/companies/clients"]),
                    scope: SCOPES.BUYERS,
                  },
                  // {
                  //   name: "PageHeader.movies",
                  //   handleExpand,
                  //   badge: this.getNavCount("movies"),
                  //   route: "/movies",
                  //   Icon: MoviesIcon,
                  //   isSelected: this.isActiveMenu(["/movies"]),
                  //   scope: SCOPES.MOVIES,
                  // },
                ]}
                location={location}
              />
              <NavMenuAccordion
                name="PageHeader.reports"
                isExpanded={isExpandedNav}
                menus={[
                  {
                    name: "PageHeader.reports",
                    handleExpand,
                    patch: this.getNavCount("campaigns"),
                    route: "/reports",
                    Icon: ReportsIcon,
                    isSelected: this.isActiveMenu(["/reports"]),
                    scope: SCOPES.REPORTS,
                  },
                ]}
                location={location}
              />
              <NavMenuAccordion
                name="PageHeader.pendingApprovalNavHeading"
                isExpanded={isExpandedNav}
                menus={[
                  {
                    name: "PageHeader.mediaRightsApproval",
                    handleExpand,
                    badge: this.getNavCount("mediaApprovals"),
                    route: "/media-approval",
                    Icon: PendingApprovalIcon,
                    persistentBadge: true,
                    isSelected: this.isActiveMenu(["/media-approval"]),
                  },
                  {
                    name: "PageHeader.campaignApprovals",
                    handleExpand,
                    badge: this.getNavCount("campaignApproval"),
                    route: "/campaign-approvals",
                    persistentBadge: true,
                    isSelected: this.isActiveMenu(["/campaign-approval"]),
                    scope: SCOPES.CAMPAIGNS,
                    scopeAction: SCOPE_ACTIONS.APPROVE,
                  },
                  {
                    name: "PageHeader.brandVerifications",
                    handleExpand,
                    patch: this.getNavCount("brandVerifications"),
                    route: "/brand-verifications",
                    isAlert: true,
                    isSelected: this.isActiveMenu(["/brand-verifications", "/brand-merge"]),
                    scope: SCOPES.BRAND_VERIFICATIONS,
                  },
                ]}
                location={location}
              />

              <NavMenuAccordion
                name="PageHeader.companiesNavHeading"
                isExpanded={isExpandedNav}
                menus={[
                  {
                    name: "PageHeader.sellers",
                    handleExpand,
                    badge: this.getNavCount("companies"),
                    route: "/companies/media_agencies",
                    Icon: CompaniesIcon,
                    isSelected: this.isActiveMenu([
                      "/companies/exhibitors",
                      "/companies/media_agencies",
                    ]),
                    scope: SCOPES.COMPANIES_MANAGE,
                  },
                ]}
                location={location}
              />

              <NavMenuAccordion
                name="PageHeader.otherNavHeading"
                isExpanded={isExpandedNav}
                menus={[
                  {
                    isSelected: this.isActiveMenu(["/list"]),
                    badge: this.getNavCount("list"),
                    Icon: ListIcon,
                    name: "PageHeader.lists",
                    handleExpand,
                    route: "/lists",
                    scope: SCOPES.LISTS,
                  },
                ]}
                location={location}
              />
            </>
          )}
        </div>
        <FooterView
          name={userName}
          companyName={companyName}
          isActiveMenu={(r) => this.isActiveMenu([r])}
          isExpanded={isExpandedNav}
          isPinned={isExpanded}
          menuList={this.shouldShowNavigationItems()}
          menus={Menus}
          isAdminApp={isAdminApp}
          onClick={() => handlePinned(!isExpanded)}
          userData={this.props.userData}
        />
      </div>
    );
  }
}

export default NavigationBar;
