function escapeCSV(field) {
  if (field.includes('"') || field.includes(",") || field.includes("\n")) {
    return `"${field.replace(/"/g, '""')}"`;
  }
  return field;
}

export async function convertTextToCsvString(inputText, customHeader = "") {
  let separator = "auto";

  // Determine the separator
  if (inputText.includes(",")) separator = ",";
  else if (inputText.includes("\n")) separator = "\n";
  else separator = " ";

  // Split the input text into an array
  const dataArray = inputText
    .split(separator)
    .map((item) => item.trim())
    .filter(Boolean);

  // Prepare the CSV data
  const csvData = dataArray.map((item) => [escapeCSV(item)]);

  // Add the custom header
  if (customHeader) {
    csvData.unshift([escapeCSV(customHeader)]);
  }

  // Convert to CSV string
  const csvString = csvData.map((row) => row.join(",")).join("\n");

  //convert to csv file
  return csvString;
}
