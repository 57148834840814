import classNames from "classnames";
import { isEmpty } from "lodash";
import { Icons } from "prefab";
import queryString from "query-string";
import React, { Component } from "react";
import { FormattedNumber } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { ExpandTable } from "workbench";
import { getCampaign } from "../../../../api";
import * as constants from "../../../../constants";
import pageStyles from "../../../../styles/App.module.scss";
import styles from "../../../../styles/Campaigns/Campaigns.module.scss";
import {
  bindDispatch,
  checkScopeAuth,
  checkScopes,
  createdAtColumn,
  createdByColumn,
  emptyDataMessage,
  formatDateTime,
  getParams,
  isSlateAdminCompanyUser,
  lastUpdatedAtColumn,
  lastUpdatedByColumn,
  modifyTableColumns,
} from "../../../../utils";
import { combineDateTimeToISO } from "../../../../utils/formatDateTime";
import ActionIcon from "../../../common/ActionIcon";
import { ActiveTableCell, ActiveTableCellRowFromArray } from "../../../common/ActiveTableCell";
import BulkActionButtons from "../../../common/BulkActionButtons";
import FilterChips from "../../../common/Filters/FilterChips";
import FooterControls from "../../../common/FooterControls";
import LocalizedButton from "../../../common/LocalizedButton";
import PageLoadingWithTable from "../../../common/PageLoadingWithTable";
import TableControls from "../../../common/TableControls";
import TableLoading from "../../../common/TableLoading";
import UserTableAccessor from "../../../common/UserTableAccessor";
import WithFetchList from "../../../hoc/withFetchList";
import AddMaxPlaysPerScreen from "../actions/AddMaxPlaysPerScreen";
import CancelCampaigns from "../bulkActions/CancelCampaigns";
import MoveCampaigns from "../bulkActions/MoveCampaigns";
import PauseOrResumeActions from "../bulkActions/PauseOrResumeActions";
import {
  getContentAvailabilityStatus,
  getStatus,
  // getStatusIconLegend,
  isCampaignStatusChangesAvailable,
  validateCampaignsAction,
} from "../utils";

const { CancelIcon, PauseIcon, PlayIcon, EditIcon, RemoveIcon, SwapIcon } = Icons;
class CampaignsList extends Component {
  state = {
    selectedColumns: [
      "Campaign",
      "Created For",
      "Advertiser",
      "Start Date",
      "End Date",
      "Status",
      "Content Availability",
      "Effective Rate",
      "Created By",
      "Last Updated On",
    ],
    selection: {
      parentSelection: [],
      childSelection: [],
    },
    currentCampaign: {},
    openedBulkAction: null,
    showAddMaxPlaysPerScreenPanel: false,
  };
  componentDidMount = () => {
    const {
      match: { params },
      fetchData,
      history,
    } = this.props;
    const query = queryString.parse(history.location.search);
    if (params.action === "addMaxPlaysPerScreen" && query.editCampaignId) {
      this.setState({
        showAddMaxPlaysPerScreenPanel: true,
      });
    }
    fetchData();
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  onRowSelect = (selection) =>
    this.setState({
      selection,
    });

  handleCampaignAction = (action, props) => {
    this.setState({
      openedBulkAction: action,
      currentCampaign: props,
    });
  };

  handleCampaignClearAction = (action, props) => {
    const { ps, page, sort, filters } = this.props;
    this.props.actions.pauseOrResumeCampaigns(
      [props.id],
      action,
      {
        clearPause: true,
      },
      getParams({
        ps,
        page,
        sort,
      }),
      false,
      filters
    );
  };

  handleCampaignCancelAction = (props) => {
    const { ps, page, sort, filters } = this.props;
    const { CAMPAIGN_TYPE } = constants;
    this.props.actions.cancelCampaigns(
      { ids: [props.id], fromDate: "", clearCancellation: true },
      CAMPAIGN_TYPE.CAMPAIGN,
      getParams({
        ps,
        page,
        sort,
      }),
      () => {},
      false,
      filters
    );
  };

  renderIconColumn = (props, url, isChild = false) => {
    const { CAMPAIGN_ACTIONS, CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST, CAMPAIGN_STATUSES } = constants;
    return (
      <ActionIcon
        iconProps={[
          // {
          //   toolTip: "Tooltip.view",
          //   url: url,
          //   iconName: "ViewIcon",
          // },
          {
            toolTip: "Tooltip.moreActions",
            iconName: "MoreVerticalIcon",
            dropdown: checkScopes(
              [
                {
                  text: "Edit",
                  url: `/campaign-create/${props.id}/details`,
                  icon: <EditIcon />,
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(
                      props.status
                    ),
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                // {
                //   text: "Logs",
                //   icon: <LogsIcon />,
                //   //data from log api should be fetched here by url
                //   url: `/logs/ref/${props.id}`,
                //   scope: constants.SCOPES.LOGS,
                // },
                {
                  text: "Pause",
                  onClick: () => {
                    this.handleCampaignAction(CAMPAIGN_ACTIONS.PAUSE, props);
                  },
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
                      props.status
                    ),
                  icon: <PauseIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                {
                  text: "Clear Pause",
                  onClick: () => this.handleCampaignClearAction(CAMPAIGN_ACTIONS.PAUSE, props),
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.PAUSE].includes(
                      props.status
                    ),
                  icon: <RemoveIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                {
                  text: "Resume",
                  onClick: () => {
                    this.setState({
                      openedBulkAction: CAMPAIGN_ACTIONS.RESUME,
                      currentCampaign: props,
                    });
                  },
                  hideMenuItem:
                    isChild ||
                    !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.RESUME].includes(
                      props.status
                    ),
                  icon: <PlayIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                // {
                //   text: "Clear Resume",
                //   onClick: () => this.handleCampaignClearAction(CAMPAIGN_ACTIONS.RESUME, props),
                //   hideMenuItem:
                //     isChild ||
                //     !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.RESUME].includes(
                //       props.status
                //     ),
                //   icon: <CancelIcon />,
                //   scope: constants.SCOPES.CAMPAIGNS,
                //   scopeAction: constants.SCOPE_ACTIONS.WRITE,
                // },
                {
                  text: "Cancel",
                  onClick: () => this.handleCampaignAction(CAMPAIGN_ACTIONS.CANCEL, props),
                  hideMenuItem: Boolean(
                    isChild ||
                      !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                        props.status
                      )
                  ),
                  icon: <CancelIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                {
                  text: "Clear Cancel",
                  onClick: () => this.handleCampaignCancelAction(props),
                  hideMenuItem: Boolean(
                    isChild ||
                      !CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.CANCEL].includes(
                        props.status
                      )
                  ),
                  icon: <RemoveIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
                {
                  text: "Move",
                  onClick: () => this.handleCampaignAction(CAMPAIGN_ACTIONS.MOVE, props),
                  //hide Move if campaign!==draft
                  hideMenuItem: !(props.status === CAMPAIGN_STATUSES.DRAFT),
                  icon: <SwapIcon />,
                  scope: constants.SCOPES.CAMPAIGNS,
                  scopeAction: constants.SCOPE_ACTIONS.WRITE,
                },
              ],
              this.props.userData
            ),
          },
        ]}
      />
    );
  };

  closeBulkAction = () => {
    this.setState({ openedBulkAction: null });
  };

  openBulkAction = (bulkAction) => {
    this.setState({ openedBulkAction: bulkAction });
  };

  handleAddMaxPlaysPerScreen = (id) => {
    const { history } = this.props;
    const search = queryString.stringify({
      ...queryString.parse(history.location.search),
      editCampaignId: id,
    });
    history.push(`/campaigns/addMaxPlaysPerScreen?${search}`);
    this.setState({
      showAddMaxPlaysPerScreenPanel: true,
    });
  };

  handleCloseAddMaxPlaysPerScreenPopup = () => {
    const { history } = this.props;
    const search = queryString.stringify({
      ...queryString.parse(history.location.search),
      editCampaignId: undefined,
    });
    history.replace(`/campaigns?${search}`);
    this.setState({
      showAddMaxPlaysPerScreenPanel: false,
    });
  };

  updateCampaignMaxPlaysPerScreen = (data) => {
    const { history } = this.props;
    this.props.actions.updateCampaignDetails(data);
    const search = queryString.stringify({
      ...queryString.parse(history.location.search),
      editCampaignId: undefined,
    });
    history.replace(`/campaigns?${search}`);
    this.setState({
      showAddMaxPlaysPerScreenPanel: false,
    });
  };

  renderMaxPlaysPerScreenValue = (props) => {
    const { userData } = this.props;
    const { SCOPES, SCOPE_ACTIONS, CAMPAIGN_STATUSES } = constants;
    const hasWriteScope = checkScopeAuth(userData, SCOPES.CAMPAIGNS, SCOPE_ACTIONS.WRITE);
    if (props.original.status === CAMPAIGN_STATUSES.CONSUMED && hasWriteScope) {
      return (
        <div className={styles.updateButtonWrapper}>
          <LocalizedButton
            id="edit"
            iconName="AddIcon"
            className={styles.updateButton}
            onClick={() => this.handleAddMaxPlaysPerScreen(props.original.id)}
            text="Button.update"
            scope={SCOPES.CAMPAIGNS}
            scopeAction={SCOPE_ACTIONS.WRITE}
            userData={userData}
          />
        </div>
      );
    }
    return <FormattedNumber value={props.value} />;
  };

  //SLATE-1369 Nov10-2023 Anushree:- createNewCampaign used in addButtonProps moved to CampaignLanding page
  // createNewCampaign = () => {
  //   const { history } = this.props;
  //   history.replace(`/campaign-create`);
  // };

  render = () => {
    const {
      history,
      match: { params },
      campaigns: { campaigns, isCampaignsLoading },
      filters,
      userData,
      sort,
      ps,
      page,
      onSortedChange,
      onQueryChange,
      isFilterLoading,
    } = this.props;
    if (isCampaignsLoading && !campaigns.data) return <PageLoadingWithTable />;

    const {
      selectedColumns,
      reorderedColumns,
      selection,
      openedBulkAction,
      currentCampaign,
      showAddMaxPlaysPerScreenPanel,
    } = this.state;
    const query = queryString.parse(history.location.search);
    const resultsCount = campaigns.totalCount || 0;
    const { CAMPAIGN_ACTIONS, CAMPAIGN_TYPE } = constants;
    const selectedCampaignData =
      selection.parentSelection.length > 0 && isEmpty(currentCampaign)
        ? campaigns.data.filter((row) => selection.parentSelection.includes(row.id))
        : [currentCampaign];
    //isPaused: checks for all selected campaigns if Pause action is allowed
    //const isPaused = validateCampaignsAction(campaigns, selection, CAMPAIGN_ACTIONS.PAUSE);
    //isPaused is determined bu current bulk action
    const isPaused = openedBulkAction === CAMPAIGN_ACTIONS.PAUSE;
    //isEdit: checks for all selected campaigns if any campaign has openedBukAction previosuly defiened within its statusChanges array with toDate > today
    const isEdit = isCampaignStatusChangesAvailable(
      selectedCampaignData,
      //status related to currently openedBulkAction passed
      isPaused ? constants.CAMPAIGN_STATUSES.PAUSED : constants.CAMPAIGN_STATUSES.ACTIVE
    );
    // const campaignHeader =
    //   isEdit && isPaused
    //     ? "RightPanelHeader.editPauseCampaign"
    //     : isEdit
    //     ? "RightPanelHeader.editResumeCampaign"
    //     : isPaused
    //     ? "RightPanelHeader.pauseCampaign"
    //     : "RightPanelHeader.resumeCampaign";

    //SLATE-1364 Nov09-2023 Anushree:- wrapper function to clear bulk selection before calling the filter function
    const clearBulkSelectionOnFilterWrapper = (filterFunction) => (...args) => {
      //clearing bulk selection
      this.onRowSelect({
        parentSelection: [],
        childSelection: [],
      });
      //calling filter function
      this.props[filterFunction](...args);
    };
    //Using the wrapper function
    const wrappedOnFilterSelect = clearBulkSelectionOnFilterWrapper("onFilterSelect");
    const wrappedOnFilterIdSelect = clearBulkSelectionOnFilterWrapper("onFilterIdSelect");

    const isAdmin = isSlateAdminCompanyUser(userData);

    const columns = checkScopes(
      [
        {
          id: "name",
          Header: "Campaign",
          accessor: "name",
          minWidth: 200,
        },
        {
          id: "createdFor",
          Header: isSlateAdminCompanyUser(userData) ? "Created For" : "Advertiser",
          accessor: (d) =>
            ActiveTableCell(d.advertiserId, d.advertiserName, () =>
              wrappedOnFilterIdSelect(d.advertiserId)
            ),
          minWidth: 120,
        },
        {
          id: "startDate",
          Header: "Start Date",
          showTimeZone: true,
          accessor: (d) =>
            formatDateTime(combineDateTimeToISO(d.validity?.fromDate, d.validity?.startTime)),
          minWidth: 150,
        },
        {
          id: "endDate",
          Header: "End Date",
          showTimeZone: true,
          accessor: (d) =>
            formatDateTime(combineDateTimeToISO(d.validity?.toDate, d.validity?.endTime)),
          minWidth: 150,
        },
        {
          id: "targetType",
          Header: "Campaign Target Type",
          accessor: (d) =>
            ActiveTableCell(d.campaignTargetTypeId, d.campaignTargetTypeName, () =>
              wrappedOnFilterIdSelect(d.campaignTargetTypeId)
            ),
          minWidth: 165,
        },
        {
          id: "companyId",
          Header: "Company",
          accessor: (d) =>
            ActiveTableCell(d.companyId, d.companyName, () => wrappedOnFilterIdSelect(d.companyId)),
          onlyInAdmin: true,
        },
        {
          id: "status",
          Header: "Status",
          accessor: (d) => getStatus(d, wrappedOnFilterSelect),
          minWidth: 100,
        },
        {
          id: "isContentAvailable",
          Header: "Content Availability",
          accessor: (d) => getContentAvailabilityStatus(d, wrappedOnFilterSelect),
          minWidth: 130,
        },
        {
          id: "effectiveRate",
          Header: "Effective Rate",
          accessor: (d) => {
            if (!d.effectiveRate) return "";
            const er = d.effectiveRate.toString().split(".");
            if (!er[1]) return d.effectiveRate;
            er[1] = er[1].slice(0, 3);
            return er.join(".");
          },
          width: 120,
        },
        {
          id: "country",
          Header: "Country",
          accessor: (d) => {
            return (
              <div>
                {ActiveTableCellRowFromArray(d.countries, (id) => wrappedOnFilterIdSelect(id))}
              </div>
            );
          },
          minWidth: 165,
        },
        {
          id: "approvedAt",
          Header: "Approved On",
          showTimeZone: true,
          accessor: "approvedAt",
          Cell: (props) => formatDateTime(props.value),
          minWidth: 165,
        },
        {
          id: "approvedBy",
          Header: "Approved By",
          accessor: UserTableAccessor("approvedBy", wrappedOnFilterIdSelect),
          minWidth: 165,
        },
        { ...createdAtColumn(), minWidth: 150 },
        { ...createdByColumn(wrappedOnFilterIdSelect), minWidth: 120 },
        { ...lastUpdatedByColumn(wrappedOnFilterIdSelect), minWidth: 150 },
        { ...lastUpdatedAtColumn(), minWidth: 120 },
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          minWidth: 45,
          parentAccessor: (props) =>
            this.renderIconColumn(props, `/campaigns/campaigns/${props.id}/details`),
          childAccessor: (props) =>
            this.renderIconColumn(props, `/campaigns/target-groups/${props.id}`, true),
        },
      ],
      userData
    );

    //SLATE-1369 Nov10-2023 Anushree:- addButtonProps used in Pageheader moved to CampaignLanding page
    // const addButtonProps = {
    //   addButton: true,
    //   buttonLabel: "Button.newCampaign",
    //   iconName: "AddIcon",
    //   addButtonScope: constants.SCOPES.CAMPAIGNS,
    //   addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
    //   addNewField: this.createNewCampaign,
    // };

    const selectionInfo = {
      count: selection.parentSelection.length,
      label: selection.parentSelection.length === 1 ? "Campaign" : "Campaigns",
    };

    return (
      <div>
        {/* 
         //SLATE-1369 Nov10-2023 Anushree:- page header removed from campaignList page and moved to CampaignLandingPage
        <PageHeader
          name="Campaigns"
          count={campaigns.totalCount}
          renderRightSideComponent={() => (
            <div className="flex align-center flex-wrap">
              <HeaderButton
                logsButton
                history={history}
                logsPath={`/logs/source/${AUDIT_LOG_SOURCE.CAMPAIGNS}`}
                {...addButtonProps}
                userData={userData}
              />
            </div>
          )}
        />
        */}
        <div className={pageStyles.pageContainer}>
          {/* <div className={pageStyles.statusGroup}>
            {getStatusIconLegend(
              campaigns.data,
              constants.TAG_TYPE.CAMPAIGN_STATUS,
              this.props.onFilterSelect,
              "status",
              false,
              false
            )}
          </div> */}
          <BulkActionButtons
            isOpen={selection.parentSelection.length > 0}
            selection={[selectionInfo]}
            buttons={[
              {
                text: "Button.pauseCampaign",
                onClick: () => {
                  this.openBulkAction(CAMPAIGN_ACTIONS.PAUSE);
                },
                icon: "PauseIcon",
                isHidden: !validateCampaignsAction(campaigns, selection, CAMPAIGN_ACTIONS.PAUSE),
                checkScope: true,
                scope: constants.SCOPES.CAMPAIGNS,
                scopeAction: constants.SCOPE_ACTIONS.WRITE,
                userData,
              },
              {
                text: "Button.resumeCampaign",
                onClick: () => {
                  this.openBulkAction(CAMPAIGN_ACTIONS.RESUME);
                },
                icon: "PlayIcon",
                isHidden: !validateCampaignsAction(campaigns, selection, CAMPAIGN_ACTIONS.RESUME),
                checkScope: true,
                scope: constants.SCOPES.CAMPAIGNS,
                scopeAction: constants.SCOPE_ACTIONS.WRITE,
                userData,
              },
              {
                text: "Button.cancelCampaign",
                onClick: () => {
                  this.openBulkAction(CAMPAIGN_ACTIONS.CANCEL);
                },
                isIconRight: false,
                icon: "CancelIcon",
                isHidden: !validateCampaignsAction(campaigns, selection, CAMPAIGN_ACTIONS.CANCEL),
                checkScope: true,
                scope: constants.SCOPES.CAMPAIGNS,
                scopeAction: constants.SCOPE_ACTIONS.CANCEL,
                userData,
              },
            ]}
            onClose={() => {
              this.onRowSelect({
                parentSelection: [],
                childSelection: [],
              });
            }}
          />
          <div className={classNames("col-12 clearfix", pageStyles.pageWrapper)}>
            <TableControls
              searchBar
              columnFilter
              showRowSize
              pagination
              columns={columns}
              selectedColumns={selectedColumns}
              reorderedColumns={reorderedColumns}
              data={campaigns}
              query={query}
              ps={ps}
              page={page}
              tagTypes={[
                constants.TAG_TYPE.CAMPAIGN,
                constants.TAG_TYPE.CAMPAIGN_STATUS,
                ...(isAdmin ? [constants.TAG_TYPE.COMPANY] : []),
                constants.TAG_TYPE.CONTENT_AVAILABILITY,
                constants.TAG_TYPE.UPDATED_AT,
                constants.TAG_TYPE.CREATED_AT,
                constants.TAG_TYPE.CAMPAIGN_CODE,
                constants.TAG_TYPE.SCHEDULE_NUMBER,
                constants.TAG_TYPE.CPL,
              ]}
              onSearchFilterSelect={this.props.onFilterSelect}
              //SLATE-1364 Nov10-2023 Anushree:- bulk selection cleared before applying filters
              //onFilterChange={this.props.onFilterChange}
              onFilterChange={(e) => {
                this.onRowSelect({
                  parentSelection: [],
                  childSelection: [],
                });
                this.props.onFilterChange(e);
              }}
              onColumnFilterSelect={this.onColumnSelect}
              onRowSizeChange={onQueryChange}
              filters={filters}
            />
            <FilterChips
              selected={filters}
              showResultsCount
              resultsCount={resultsCount}
              onFilterChange={this.props.onFilterChange}
            />
            <ExpandTable
              data={campaigns.data}
              loading={isCampaignsLoading || isFilterLoading}
              columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
              defaultPageSize={constants.DEFAULT_PAGE_SIZE}
              sorted={sort}
              onSortedChange={onSortedChange}
              // selectable={true}
              selection={selection}
              onSelect={this.onRowSelect}
              showChildSelect={false}
              childIdentificationKey="targetGroups"
              LoadingComponent={
                <TableLoading
                  columns={modifyTableColumns(columns, selectedColumns, reorderedColumns)}
                />
              }
              emptyDataMessage={emptyDataMessage(
                `EmptyDataMessage.noCampaignFound`,
                `EmptyDataMessage.noCampaignFoundBody`
              )}
              onRowClick={({ original }) =>
                history.push(`/campaigns/campaigns/${original.id}/details`)
              }
              onChildRowClick={({ original }) =>
                history.push(`/campaigns/target-groups/${original.id}`)
              }
            />
            <FooterControls
              pagination
              data={campaigns}
              query={query}
              ps={ps}
              page={page}
              onRowSizeChange={onQueryChange}
            />
          </div>
        </div>
        {(openedBulkAction === CAMPAIGN_ACTIONS.RESUME ||
          openedBulkAction === CAMPAIGN_ACTIONS.PAUSE) && (
          <PauseOrResumeActions
            //header seen in the right panel,depending on isEdit and isPaused
            header={currentCampaign.name}
            selectionInfo={
              selection.parentSelection.length > 0 && isEmpty(currentCampaign)
                ? selectionInfo
                : null
            }
            history={history}
            //selectedCampaignData are the rows selected
            selected={selectedCampaignData}
            //isPaused is true if the Pause action is allowed for all the selected campaigns.
            isPaused={isPaused}
            //isEdit is true if openedBulkAction has previously occured for any selected campaign with todate > today
            isEdit={isEdit}
            //isEdit={true}
            //validateSelectedCampaignsDurations to be checked!!
            // showEdit={validateSelectedCampaignsDurations(
            //   selectedCampaignData,
            //   isPaused ? constants.CAMPAIGN_STATUSES.PAUSED : constants.CAMPAIGN_STATUSES.ACTIVE
            // )}
            //showEdit={true}
            showEdit={true}
            //if bulk action is set then the panel is open
            isOpen={Boolean(openedBulkAction)}
            //action type -> resume or pause
            actionType={openedBulkAction}
            //set bulkaction as null on panel close
            onClose={this.closeBulkAction}
            //function called on pause or resume
            onPauseOrResumeAction={(period) => {
              this.props.actions.pauseOrResumeCampaigns(
                selectedCampaignData.map((campaign) => campaign.id),
                openedBulkAction,
                period,
                getParams({
                  ps,
                  page,
                  sort,
                }),
                false,
                filters
              );
            }}
          />
        )}
        {openedBulkAction === CAMPAIGN_ACTIONS.CANCEL && (
          <CancelCampaigns
            selectionInfo={
              selection.parentSelection.length > 0 && isEmpty(currentCampaign)
                ? selectionInfo
                : null
            }
            selected={selectedCampaignData}
            header={currentCampaign.name}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            cancelCampaigns={(data) => {
              this.props.actions.cancelCampaigns(
                { ...data, ids: selectedCampaignData.map((campaign) => campaign.id) },
                CAMPAIGN_TYPE.CAMPAIGN,
                getParams({
                  ps,
                  page,
                  sort,
                }),
                this.closeBulkAction,
                false,
                filters
              );
            }}
          />
        )}
        {openedBulkAction === CAMPAIGN_ACTIONS.MOVE && (
          <MoveCampaigns
            selected={selectedCampaignData}
            //parentValidity is null for campaign
            parentValidity={null}
            header={currentCampaign.name}
            item={CAMPAIGN_TYPE.CAMPAIGN}
            isOpen={Boolean(openedBulkAction)}
            actionType={openedBulkAction}
            onClose={this.closeBulkAction}
            moveCampaigns={(data) => {
              this.props.actions.moveCampaigns(
                { id: selectedCampaignData[0].id, ...data },
                CAMPAIGN_TYPE.CAMPAIGN,
                getParams({
                  ps,
                  page,
                  sort,
                }),
                this.closeBulkAction,
                false,
                filters
              );
            }}
          />
        )}
        {query?.editCampaignId && params.action === "addMaxPlaysPerScreen" && (
          <AddMaxPlaysPerScreen
            fetchActionId={query?.editCampaignId}
            fetchAction={getCampaign}
            isOpen={showAddMaxPlaysPerScreenPanel}
            onClose={this.handleCloseAddMaxPlaysPerScreenPopup}
            onSave={this.updateCampaignMaxPlaysPerScreen}
          />
        )}
      </div>
    );
  };
}

export const CampaignsListWithFilters = WithFetchList("getCampaigns", {
  sort: [{ id: "updatedAt", desc: true }],
})(CampaignsList);

const mapStateToProps = createSelector(
  (state) => state.campaigns,
  (state) => state.userData,
  (campaigns, userData) => ({ campaigns, userData: userData.user })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(CampaignsListWithFilters));
