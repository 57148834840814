import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createSelector } from "reselect";
import { BUTTON_TYPE, BaseButton, SIZE } from "prefab";
import { FORM_FIELD_TYPE } from "../../../../../constants";
import { bindDispatch } from "../../../../../utils";
import RightPanel from "../../../../common/RightPanel";
import Form from "../../../../common/Form";
import styles from "../../../../../styles/Inventory/Settings.module.scss";

export class EditRemarks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      remarks: "",
      originalRemarks: "", // Store original remarks for comparison
      isNewRemark: false, // Flag to track if this is a new remark
    };
  }

  componentDidMount() {
    const {
      screenSettingsEdit: { setting },
    } = this.props;

    if (setting) {
      const hasExistingRemarks = Boolean(setting.remarks);
      this.setState({
        remarks: setting.remarks || "",
        originalRemarks: setting.remarks || "",
        isNewRemark: !hasExistingRemarks,
        isLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
        isNewRemark: true,
      });
    }
  }

  closeEditPanel = () => {
    const {
      actions,
      history,
      match: { params },
    } = this.props;

    actions.cancelSettingEdit();
    history.push(`/screens/${params.screenId}/${params.tabId}`);
  };

  handleChange = (value) => {
    this.setState({ remarks: value });
  };

  handleSave = () => {
    const { onSave } = this.props;
    onSave({ remarks: this.state.remarks });
  };

  handleClear = () => {
    // Instead of clearing to empty, reset to original state
    this.setState({ remarks: this.state.originalRemarks });
  };
  isSaveEnabled = () => {
    const { remarks, originalRemarks, isNewRemark } = this.state;

    if (isNewRemark) {
      // For new remarks, don't allow empty value
      return remarks.trim() !== "";
    } else {
      // For existing remarks, allow any change including empty
      return remarks !== originalRemarks;
    }
  };

  render() {
    const { isLoading, remarks } = this.state;
    const {
      screenSettingsEdit: { setting, isOpen },
      screen,
    } = this.props;

    if (isLoading || !setting) {
      return null;
    }

    const screenName = screen?.name || "";
    const theatreName = screen?.theatre?.name || "";
    const chain = screen?.chain?.name || "";

    const fullScreenName = `${screenName} • ${theatreName}: ${chain}`;

    return (
      <RightPanel
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={this.closeEditPanel}
        header="RightPanelHeader.remarks"
        showFooter
        width={360}
        primaryButtonProps={{
          text: "Button.save",
          onClick: this.handleSave,
          disabled: !this.isSaveEnabled(),
        }}
        secondaryButtonProps={{ text: "Button.cancel", onClick: this.closeEditPanel }}
      >
        <div className={styles.editPanel}>
          <Form
            config={[
              {
                id: "screenInfo",
                type: FORM_FIELD_TYPE.LABEL,
                label: "Screen",
                value: fullScreenName || "",
                className: styles.screenInfo,
              },
              {
                id: "remarks",
                type: FORM_FIELD_TYPE.TEXT_AREA,
                label: "Remarks",
                value: remarks,
                placeholder: "Add Remarks",
                rows: 4,
                onChange: (e) => this.handleChange(e.target.value),
                required: true,
              },
            ]}
          />

          <BaseButton
            buttonText="Clear"
            buttonSize={SIZE.REGULAR}
            onClick={this.handleClear}
            buttonType={BUTTON_TYPE.DEFAULT}
            className={styles.clearButton}
            disabled={remarks === this.state.originalRemarks}
          />
        </div>
      </RightPanel>
    );
  }
}

const mapStateToProps = createSelector(
  (state) => state.screens,
  (state) => state.userData,
  (screens, userData) => ({
    screenSettingsEdit: screens.screenSettingsEdit,
    userData: userData.user,
  })
);

export default connect(mapStateToProps, bindDispatch)(withRouter(EditRemarks));
