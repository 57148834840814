import classNames from "classnames";
import { Icons } from "prefab";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { CAMPAIGN_ACTIONS, CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST } from "../../../../../constants";
import styles from "../../../../../styles/Campaigns/Campaigns.module.scss";
import { formatValidityPeriod } from "../../../../../utils";
import Collapsible from "../../../../common/Collapsible";
import InfoBlockLoading from "../../../../common/InfoBlockLoading";
import LocalizedButton from "../../../../common/LocalizedButton";
import PageLoadingWithTable from "../../../../common/PageLoadingWithTable";
import Popup from "../../../../common/Popup";
import CampaignTree from "../../CampaignStructure/CampaignTree";
import { getScrollableSummaryBlock } from "../../utils";
import CriteriaList from "../CriteriaList";

const {
  EditIcon,
  DeleteIcon,
  CopyIcon,
  ChevronDownIcon,
  LocationIcon,
  TheatreIcon,
  AddIcon,
  CueFromStartIcon,
} = Icons;

export default class TargetGroupDetails extends Component {
  static propTypes = {
    targetGroups: PropTypes.array.isRequired,
    withCampaignTree: PropTypes.bool,
    showEstimates: PropTypes.bool,
    showAdd: PropTypes.bool,
    showEdit: PropTypes.bool,
    showDuplicate: PropTypes.bool,
    showDelete: PropTypes.bool,
    showSplit: PropTypes.bool,
  };

  static defaultProps = {
    showAdd: false,
    showEstimates: false,
    withCampaignTree: false,
    showEdit: true,
    showDuplicate: true,
    showDelete: true,
    showSplit: false,
  };

  state = {
    isPopupOpen: false,
    isCampaignStructureLoading: true,
    campaignStructure: {},
    isCampaignStructureError: false,
  };

  componentDidMount = async () => {
    // if (this.props.withCampaignTree) await this.fetchCampaignTreeData();
  };

  // fetchCampaignTreeData = () => {
  //   const { params } = this.props;

  //   getCampaignTreeApi(params.campaignId)
  //     .then((campaignRes) =>
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         campaignStructure: campaignRes,
  //       })
  //     )
  //     .catch((err) => {
  //       this.setState({
  //         isCampaignStructureLoading: false,
  //         isCampaignStructureError: true,
  //         campaignStructure: null,
  //       });
  //     });
  // };

  handleEditClick = (event, id) => {
    event.stopPropagation();
    if (this.props.onEdit) {
      this.props.onEdit(id);
    }
  };

  handleDuplicateClick = (event, id) => {
    event.stopPropagation();
    if (this.props.onDuplicate) {
      this.props.onDuplicate(id);
    }
  };

  handleTargetGroupCreate = (event) => {
    event.stopPropagation();
    if (this.props.onTargetGroupCreate) {
      this.props.onTargetGroupCreate();
    }
  };

  handleConfirmDelete = (event) => {
    event.stopPropagation();
    if (this.props.onDelete) {
      this.props.onDelete(this.state.selectedId);
    }

    this.setState({
      isPopupOpen: false,
    });
  };

  handleDeleteClick = (event, id) => {
    event.stopPropagation();

    this.setState({
      isPopupOpen: true,
      selectedId: id,
    });
  };

  onDiscardChanges = (event) => {
    event.stopPropagation();

    this.setState({
      isPopupOpen: false,
    });
  };

  renderTargetGroupsCriteria = (targetGroup) => {
    const { showEstimates, countries, isDetailsLoading } = this.props;
    if (isDetailsLoading) {
      return (
        <div className={styles.collapsibleBodyContainer}>
          <InfoBlockLoading />;
        </div>
      );
    }

    if (!targetGroup.criteria) {
      return <div className={styles.collapsibleBodyContainer}>No Criteria Found</div>;
    }

    return (
      <div className={styles.collapsibleBodyContainer}>
        {showEstimates &&
          getScrollableSummaryBlock(
            {
              validity: targetGroup?.summary?.validity,
              maxPlaysPerScreen: targetGroup?.summary?.maxPlaysPerScreen,
              ...targetGroup?.summary?.estimated,
            },
            {
              xs: 12,
              sm: 4,
              md: 3,
              lg: 3,
            }
          )}
        <div className={styles.criteriaContainer}>
          <CriteriaList
            targetGroupType="theatre"
            criteria={targetGroup?.criteria}
            headerIcon={<TheatreIcon />}
            countries={countries}
            headerName="Theatre"
            withEdit={false}
          />
          <CriteriaList
            targetGroupType="location"
            criteria={targetGroup?.criteria}
            headerIcon={<LocationIcon />}
            countries={countries}
            headerName="Location"
            withEdit={false}
          />
        </div>
      </div>
    );
  };

  handleSplitClick = (event, id) => {
    event.stopPropagation();
    if (this.props.onSplit) {
      this.props.onSplit(id);
    }
  };

  isDeleteEnabled = (targetGroup, campaignStatus) => {
    // Only enable delete in these scenarios
    if (campaignStatus === "Draft" && targetGroup.status === "Draft") return true;
    if (campaignStatus === "Planned" && targetGroup.status === "Planned") return true;
    if (
      (campaignStatus === "Active" || campaignStatus === "Paused") &&
      (targetGroup.status === "Draft" || targetGroup.status === "Planned")
    ) {
      return true;
    }

    // Disable for all other combinations
    return false;
  };

  render() {
    const {
      targetGroups,
      showDelete,
      showDuplicate,
      showEdit,
      showAdd,
      showSplit,
      withCampaignTree,
      isLoading,
    } = this.props;
    const { isPopupOpen, isCampaignStructureError } = this.state;

    if (isLoading) return <PageLoadingWithTable />;
    return (
      <>
        <div className="col-12 clearfix flex">
          <div className="col-12">
            {targetGroups.map((targetGroup, index) => {
              return (
                <Collapsible
                  key={index}
                  className="col-12 flex flex-column"
                  header={(isOpen, toggleCollapse) => (
                    <div
                      className={styles.collapsibleHeaderContainer}
                      onClick={() => {
                        this.props.getTargetGroupDetails(targetGroup.id, "list");
                        toggleCollapse();
                      }}
                    >
                      <div className={styles.title}>
                        <span
                          className={classNames(styles.icon, {
                            [styles.open]: isOpen,
                            [styles.close]: !isOpen,
                          })}
                        >
                          <ChevronDownIcon />
                        </span>
                        {`${targetGroup.name}`}
                      </div>
                      <div className={styles.subTitle}>
                        <div className={styles.info}>
                          {formatValidityPeriod(targetGroup?.validity, "DD MMM YYYY")}
                        </div>
                      </div>
                      <div className={styles.collapseActions}>
                        {showEdit &&
                          CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.EDIT].includes(
                            targetGroup.status
                          ) && (
                            <div
                              className={styles.button}
                              onClick={(event) => this.handleEditClick(event, targetGroup.id)}
                            >
                              <EditIcon />
                            </div>
                          )}
                        {showSplit &&
                          CAMPAIGN_ACTIONS_ALLOWED_STATUS_LIST[CAMPAIGN_ACTIONS.SPLIT].includes(
                            targetGroup.status
                          ) && (
                            <div
                              className={styles.button}
                              onClick={(event) => this.handleSplitClick(event, targetGroup.id)}
                            >
                              <CueFromStartIcon />
                            </div>
                          )}
                        {showDuplicate && (
                          <div
                            className={styles.button}
                            onClick={(event) => this.handleDuplicateClick(event, targetGroup.id)}
                          >
                            <CopyIcon />
                          </div>
                        )}
                        {showDelete && (
                          <div
                            className={
                              this.isDeleteEnabled(targetGroup, this.props.campaign.status)
                                ? styles.button
                                : styles.disabledBtn
                            }
                            onClick={(event) => {
                              if (this.isDeleteEnabled(targetGroup, this.props.campaign.status)) {
                                this.handleDeleteClick(event, targetGroup.id);
                              } else {
                                event.stopPropagation();
                              }
                            }}
                          >
                            <DeleteIcon />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  body={() => this.renderTargetGroupsCriteria(targetGroup)}
                />
              );
            })}

            {showAdd && (
              <LocalizedButton
                minimal={"true"}
                text="Button.createTargetGroup"
                icon={<AddIcon width={16} height={16} />}
                onClick={this.handleTargetGroupCreate}
                className={`${styles.button} ${styles.createButton}`}
              />
            )}
          </div>
          {withCampaignTree && !isCampaignStructureError && (
            <div className={styles.campaignTree}>
              <CampaignTree
              // isLoading={isCampaignStructureLoading}
              // campaignTreeData={campaignStructure ?? []}
              />
            </div>
          )}
        </div>
        <Popup
          isOpen={isPopupOpen}
          onClose={this.onDiscardChanges}
          onConfirm={this.handleConfirmDelete}
          title="Popup.title"
          body="Popup.body"
        />
      </>
    );
  }
}
