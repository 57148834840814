import { values } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactGA from "react-ga";
import { searchTags } from "../../../../api";
import { FORM_FIELD_TYPE, TAG_TYPE } from "../../../../constants";
import { getTagTypeDisplayName } from "../../../../utils";

import Form from "../../../common/Form";

class ReportSearchFilterSection extends Component {
  static propTypes = {
    tagType: PropTypes.oneOf(values(TAG_TYPE)).isRequired,
    selected: PropTypes.array,
    onFilterChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    selected: [],
  };

  onFilterChange = (filter) => {
    this.props.onFilterChange(filter);
    if (filter?.type)
      ReactGA.event({ category: "filter", action: "right-panel-search", label: filter.type });
  };

  render() {
    const { tagType, selected, filterType, filters } = this.props;
    const placeholder = `Search ${getTagTypeDisplayName(tagType)}`;
    const currentFilters = filters.filter((f) => f.type === TAG_TYPE.THEATRE).map((f) => f.id);
    return (
      <Form
        isFormGroup={false}
        config={[
          {
            id: "search",
            label: "",
            type: FORM_FIELD_TYPE.API_SEARCH,
            size: {
              lg: 16,
              md: 16,
            },
            placeholder: placeholder,
            query: selected[0]?.displayName ?? "",
            fetchAction: (text) => searchTags(text, tagType, currentFilters),
            parseResults: (response) =>
              response.map((res) => {
                const { city, province, country } = res.context || {};
                const tag =
                  tagType === TAG_TYPE.SCREEN || tagType === TAG_TYPE.THEATRE
                    ? [city?.name, province?.name, country?.name].filter(Boolean).join(", ")
                    : res.type;

                return {
                  ...res,
                  name: res.displayName,
                  tag: tag,
                  isSingleSelect: filterType.selectionType === "single",
                };
              }),
            onSelect: this.onFilterChange,
            showSubText: true,
            showListOnClick:
              tagType === TAG_TYPE.SCREEN && filters.some((f) => f.type === TAG_TYPE.THEATRE),

            onEmpty: () => {
              this.onFilterChange(filters.find((f) => f.type === tagType));
            },
            selectedItem: selected,
          },
        ]}
      />
    );
  }
}

export default ReportSearchFilterSection;
