/* 
1.Imports React components, Redux connectors, utility functions etc.

2.Renders a ParentBrandsList component with withRouter and connect to get access to React Router and Redux.

3.ParentBrandsList manages state for selected columns and column order.

4.On mount, it fetches the parent brand data using the fetchData prop from the WithFetchList HOC.

5.Defines columns to display, including name, count, type, categories etc. Some columns render custom ActiveTableCell components.

6.Renders a BrandTable to display the data with the defined columns.

7.Allows editing parent brands and navigating to their details page.

8.Connects to Redux to get state like brands data, business types, user data etc.

9.Exports the connected ParentBrands component with data fetching logic.
*/
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import {
  bindDispatch,
  getBusinessType,
  getCategoryNames,
  checkScopes,
  lastUpdatedByColumn,
  lastUpdatedAtColumn,
  createdByColumn,
  createdAtColumn,
  getColumnsByViewport,
  isSlateAdminCompanyUser,
} from "../../../../../utils";
import * as constants from "../../../../../constants";
import WithFetchList from "../../../../hoc/withFetchList";
import ActionIcon from "../../../../common/ActionIcon";
import {
  ActiveTableCell,
  ActiveTableCellColumnFromArray,
} from "../../../../common/ActiveTableCell";
import BrandTable from "../BrandTableView";
import { BRAND_LIST_COMMON_TAG_TYPES } from "../brandConstants";
import EditBrand from "../EditBrand";
import { getStatusDisplay } from "../../../campaigns/utils";

const DEFAULT_COLUMNS = [
  "Parent Brand",
  "Brand Type",
  "Categories",
  "Clients",
  "Company",
  "Status",
  "Last Updated By",
  "Created By",
];
const MOBILE_COLUMNS = ["Parent Brand", "Brand Type", "Categories", "Advertisers"];

class ParentBrandsList extends Component {
  state = {
    selectedColumns: getColumnsByViewport(
      this.props.app.pageDimensions,
      MOBILE_COLUMNS,
      DEFAULT_COLUMNS
    ),
  };

  componentDidMount = () => {
    const { fetchData } = this.props;
    fetchData(null, true, [{ parentTagId: this.props.parentTagId }]);
  };

  onColumnSelect = (selectedColumns, reorderedColumns) =>
    this.setState({ selectedColumns, reorderedColumns });

  editParentBrand = (prop) => {
    const { actions, editBrand } = this.props;
    editBrand(prop.original.id);
    actions.editBrand(prop.original);
  };

  editIndividualBrand = (prop) => {
    const { actions, editBrand } = this.props;
    editBrand(prop.original.id);
    actions.getIndividualBrandById({ id: prop.original.id }, true);
  };

  renderAdvertisers = (advertisers) => {
    if (!advertisers || advertisers.length === 0) return "";
    return advertisers.map((ad, i) => <div key={i}>{ad}</div>);
  };
  render = () => {
    const {
      history,
      match: { params },
      brands: { parentBrandList, editBrand },
      businessTypes: { list },
      userData,
      addNewField,
      isViewOnly,
      hiddenColumn,
      title,
    } = this.props;
    const { selectedColumns, reorderedColumns } = this.state;

    const isAdmin = isSlateAdminCompanyUser(userData);

    const columns = checkScopes(
      [
        {
          id: "name",
          Header: "Parent Brand",
          parentAccessor: "name",
          childAccessor: "name",
        },
        {
          id: "count",
          Header: "Individual Brand Count",
          sortable: false,
          accessor: (d) => (d.individualBrands ? d.individualBrands.length : ""),
        },
        {
          id: "brandType",
          Header: "Brand Type",
          parentAccessor: (d) =>
            ActiveTableCell(d.businessTypeTag, getBusinessType(list, d.businessType), () =>
              this.props.onFilterIdSelect(d.businessTypeTag)
            ),
        },
        {
          id: "categories",
          Header: "Categories",
          sortable: false,
          parentAccessor: (d) =>
            getCategoryNames(d.categories, (id) => this.props.onFilterIdSelect(id)),
          childAccessor: (d) => getCategoryNames(d.categories),
        },
        {
          id: "clients",
          Header: "Clients",
          sortable: false,
          parentAccessor: (d) =>
            ActiveTableCellColumnFromArray(d.advertisers, this.props.onFilterIdSelect),
          childAccessor: (d) => ActiveTableCellColumnFromArray(d.advertisers),
        },
        {
          id: "createdByCompanyName",
          Header: "Company",
          accessor: (d) =>
            ActiveTableCell(d.createdByCompanyId, d.createdByCompanyName, () =>
              this.props.onFilterIdSelect(d.createdByCompanyId)
            ),
          onlyInAdmin: true,
        },
        {
          id: "status",
          Header: "Status",
          accessor: (d) =>
            getStatusDisplay(
              d.status,
              this.props.onFilterSelect,
              constants.TAG_TYPE.BRAND_STATUS,
              false
            ),
          onlyInAdmin: false,
        },
        lastUpdatedByColumn(this.props.onFilterIdSelect),
        lastUpdatedAtColumn(),
        createdByColumn(this.props.onFilterIdSelect),
        createdAtColumn(),
        {
          ...constants.DEFAULT_ACTION_ICON_COLUMN,
          width: isViewOnly ? 64 : 96,
          parentCell: (props) => (
            <ActionIcon
              iconProps={checkScopes(
                [
                  {
                    toolTip: "Tooltip.view",
                    url: `/brands/parent-brands/${props.original.id}/details`,
                    iconName: "ViewIcon",
                  },
                  {
                    id: "edit",
                    scope: constants.SCOPES.BRANDS,
                    scopeAction: constants.SCOPE_ACTIONS.WRITE,
                    toolTip: "Tooltip.update",
                    onClick: () => this.editParentBrand(props),
                    iconName: "EditIcon",
                  },
                ].filter((icon) => (isViewOnly ? icon.id !== "edit" : icon)),
                userData
              )}
            />
          ),
          childCell: (props) => (
            <ActionIcon
              iconProps={checkScopes(
                [
                  {
                    toolTip: "Tooltip.view",
                    url: `/brands/individual-brands/${props.original.id}/details`,
                    iconName: "ViewIcon",
                  },
                  {
                    id: "edit",
                    scope: constants.SCOPES.BRANDS,
                    scopeAction: constants.SCOPE_ACTIONS.WRITE,
                    toolTip: "Tooltip.update",
                    onClick: () => this.editIndividualBrand(props),
                    iconName: "EditIcon",
                  },
                ].filter((icon) => (isViewOnly ? icon.id !== "edit" : icon)),
                userData
              )}
            />
          ),
        },
      ],
      userData
    ).filter((column) => (hiddenColumn ? column.id !== hiddenColumn : column));
    const addButtonProps = {
      addButton: true,
      buttonLabel: "Button.addParentBrand",
      addButtonScope: constants.SCOPES.BRANDS,
      addButtonScopeAction: constants.SCOPE_ACTIONS.WRITE,
      addNewField: addNewField,
      userData,
    };

    const tagTypes = [
      constants.TAG_TYPE.PARENT_BRAND,
      ...(isAdmin ? [constants.TAG_TYPE.COMPANY] : []),
      constants.TAG_TYPE.BRAND_STATUS,
      ...BRAND_LIST_COMMON_TAG_TYPES,
    ];

    return (
      <>
        <BrandTable
          dataList={parentBrandList}
          columns={columns}
          selectedColumns={selectedColumns}
          reorderedColumns={reorderedColumns}
          onColumnSelect={this.onColumnSelect}
          addButtonProps={addButtonProps}
          childIdentificationKey="individualBrands"
          tagTypes={tagTypes}
          onRowClick={({ original }) =>
            history.push(`/brands/parent-brands/${original.id}/details`)
          }
          onChildRowClick={({ original }) =>
            history.push(`/brands/individual-brands/${original.id}/details`)
          }
          {...this.props}
        />
        {!isViewOnly && (
          <EditBrand
            title={title ?? params?.tabId}
            brand={editBrand.brand}
            fetchDataList={() => {
              // Reset filter list and then trigger data refresh
              this.props.onFilterChange([]);
            }}
            {...this.props}
          />
        )}
      </>
    );
  };
}
export const ParentBrands = WithFetchList("getParentBrandList", {
  sort: [{ id: "name" }],
})(ParentBrandsList);

const mapStateToProps = createSelector(
  (state) => state.brands,
  (state) => state.businessTypes,
  (state) => state.productIdentificationNumberType,
  (state) => state.userData,
  (state) => state.app,
  (brands, businessTypes, productIdentificationNumberType, userData, app) => ({
    brands,
    businessTypes,
    productIdentificationNumberType,
    userData: userData.user,
    app,
  })
);
export default connect(mapStateToProps, bindDispatch)(withRouter(ParentBrands));
