import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { BUTTON_TYPE, Icons } from "prefab";
import styles from "../../../../styles/Campaigns/CampaignSteps.module.scss";
import LocalizedButton from "../../../common/LocalizedButton";
import { CAMPAIGN_STEPS } from "../../../../constants";

const { CampaignIcon, CheckboxSelectedIcon, TheatreIcon, ListIcon, ContentIcon } = Icons;

const stepConfig = [
  {
    number: CAMPAIGN_STEPS.CAMPAIGN,
    name: "Campaign Details",
    icon: <CampaignIcon />,
  },
  {
    number: CAMPAIGN_STEPS.TARGET_GROUP,
    name: "Target Groups",
    icon: <TheatreIcon />,
  },
  {
    number: CAMPAIGN_STEPS.MEDIA,
    name: "Media",
    icon: <ContentIcon />,
  },
  {
    number: CAMPAIGN_STEPS.REVIEW,
    name: "Review Theatres",
    icon: <ListIcon />,
  },
  {
    number: CAMPAIGN_STEPS.FINISH,
    name: "Summary",
    icon: <CheckboxSelectedIcon />,
  },
];

class CampaignSteps extends Component {
  static propTypes = {
    activeStep: PropTypes.number.isRequired,
    validTillStep: PropTypes.number.isRequired,
    onChangeStep: PropTypes.func.isRequired,
    onSaveDraft: PropTypes.func.isRequired,
    onReset: PropTypes.func,
    onSendProposal: PropTypes.func,
    onCreateCampaign: PropTypes.func,
    isAdminUser: PropTypes.bool,
    disableSave: PropTypes.bool,
    disableReset: PropTypes.bool,
  };

  static defaultProps = {
    isAdminUser: false,
    disableSave: false,
    disableReset: false,
    onReset: null,
    onSendProposal: null,
    onCreateCampaign: null,
  };

  renderStep = (step) => {
    const { activeStep, validTillStep, onChangeStep } = this.props;
    const isActive = activeStep === step.number || activeStep === -1;
    const isValid = step.number <= validTillStep;
    //SLATE-1417 Dec6 Anushree:- additional styles added for completed steps icon and progress separator
    //const icon = step.number <= validTillStep && !isActive ? <CheckboxSelectedIcon /> : step.icon;
    const isCompleted = step.number < validTillStep && !isActive;
    return (
      <Fragment key={step.number}>
        <div
          onClick={() => (isValid && !isActive ? onChangeStep(step.number) : null)}
          className={classNames(styles.step, {
            [styles.valid]: isValid,
            [styles.active]: isActive,
          })}
        >
          <div className={classNames(styles.icon, isCompleted ? styles.completed : styles.pending)}>
            {isCompleted ? <div>&#10004;</div> : step.number}
          </div>
          <div
            className={classNames(styles.name, isCompleted ? styles.complete : "")}
          >{`${step.name}`}</div>
        </div>
        <span
          className={classNames(styles.separator, isCompleted ? styles.completed : styles.pending)}
        />
      </Fragment>
    );
  };

  render() {
    const {
      history,
      activeStep,
      onChangeStep,
      onSendProposal,
      onReset,
      onCreateCampaign,
      isAdminUser,
      disableSave,
      disableReset,
      campaign,
    } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.stepContainer}>{stepConfig.map(this.renderStep)}</div>
        <div className={styles.buttons}>
          {activeStep > CAMPAIGN_STEPS.CAMPAIGN && (
            <LocalizedButton
              id="back"
              buttonType={BUTTON_TYPE.SECONDARY}
              onClick={() => onChangeStep(activeStep - 1)}
              text="Button.back"
            />
          )}
          {activeStep !== CAMPAIGN_STEPS.FINISH && (
            <LocalizedButton
              id="save"
              disabled={disableSave}
              onClick={() => onChangeStep(activeStep + 1)}
              text="Button.saveAndContinue"
              buttonType={BUTTON_TYPE.PRIMARY}
              iconName="ArrowRightIcon"
              iconPos="right"
            />
          )}
          <LocalizedButton
            id="reset"
            buttonType={BUTTON_TYPE.SECONDARY}
            onClick={onReset}
            disabled={disableReset}
            text="Button.reset"
          />
          {activeStep === CAMPAIGN_STEPS.FINISH &&
            (isAdminUser ? (
              campaign.status === "Draft" ? (
                <LocalizedButton
                  id="createCampaign"
                  text="Button.createCampaign"
                  buttonType={BUTTON_TYPE.PRIMARY}
                  onClick={onCreateCampaign}
                />
              ) : (
                <LocalizedButton
                  id="done"
                  text="Button.done"
                  buttonType={BUTTON_TYPE.PRIMARY}
                  onClick={() => history.push("/campaigns")}
                />
              )
            ) : (
              <LocalizedButton
                id="sendProposal"
                text="Button.sendProposal"
                buttonType={BUTTON_TYPE.PRIMARY}
                onClick={onSendProposal}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default CampaignSteps;
