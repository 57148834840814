import React from "react";
import InfoBlock from "../../../../../common/InfoBlock";
import PageStyles from "../../../../../../styles/App.module.scss";
import TableInfoBlock from "../../../../../common/TableInfoBlock";
import { formatDateTime } from "../../../../../../utils";

const renderAddress = (address, city, province, country) => {
  return (
    <div>
      <i>Street Address</i>: {address.street},{address.area}
      <br />
      <i>City</i>: {city.name},<br />
      <i>State</i>: {province.name},<br />
      <i>Country</i>: {country.name},<br />
      <i>Post Code</i>: {address.postalCode}
    </div>
  );
};

const theatreDetails = (theatre) => {
  return (
    <div className={PageStyles.pageContainer}>
      <InfoBlock
        header={"Theatre Details"}
        config={[
          { title: "Theatre name", value: theatre.name },
          { title: "Theatre ID", value: theatre.id },
          { title: "Chain name", value: theatre.chain.name },
          {
            title: "Theatre type",
            value: theatre.type.name,
          },
          {
            title: "Appliance Serial Number",
            value: theatre?.wiretap?.serial,
          },
          {
            title: "AgentQ Version",
            value: theatre.agentQVersion,
          },
          {
            title: "AgentQ Last Updated On",
            value: formatDateTime(theatre.agentQUpdatedAt),
          },
          {
            title: "Theatre location type",
            value: theatre?.locationType?.name ?? "",
          },
          {
            title: "Address",
            value: renderAddress(theatre.address, theatre.city, theatre.province, theatre.country),
          },
        ]}
      />
      <TableInfoBlock
        header="Taxes"
        isLargeHeader={false}
        data={theatre.taxes}
        columns={[
          {
            Header: "Code",
            accessor: "code",
          },
          {
            Header: "Name",
            accessor: "name",
          },
          {
            Header: "Percentage",
            accessor: "taxPercentage",
          },
          {
            Header: "Description",
            accessor: "description",
          },
        ]}
      />
    </div>
  );
};

export default theatreDetails;
